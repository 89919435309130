
//----------------------------------------------------
// # Main Styles
//----------------------------------------------------

//--------------------------- 
//
// TABLE OF CONTENT 
//
// # Imports
// # Generic
// # Helpers
// # Misc
// # Toolbar
// 	 - Dropdown
//     - Container
// # Sidenav
// # Main Container
// # Page Header
// # Inline Navigation
// # Breadcrumb
// # Ghost BTN
//
//---------------------------




//----------------------------------------------------
// # Imports
//----------------------------------------------------

@import 'partials/base';
@import 'szilard'; 




//----------------------------------------------------
// # Generic
//----------------------------------------------------
/*
* {
	will-change: transform;
}
*/
body {
  background: $lightGray; 
  font-family: $defaultFont;
  overflow: hidden;
}

::-moz-selection { 
	background: rgba($defaultColor, 0.7);
	color: #fff;
}

::selection { 
	background: rgba($defaultColor, 0.7);
	color: #fff;
}

* {
	outline: none;
}

.soft-disable {
	pointer-events: none;
}

.pull-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.lock-screen {
	position: relative;
	z-index: 100;
}

.iframe-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


.youtube-wrapper {
	background: #eee;
	position: relative;

	&::before {
		content: "\eb06";
		font-family: 'icomoon' !important;
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

		color: #df8585;
		font-size: 100px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -60px;
    	margin-top: -50px;
		display: inline-block;
	}
}


.ps-scrollbar-x-rail,
.ps-scrollbar-y-rail {
	transition: none !important;
	z-index: 40;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover,
.ps-container.ps-in-scrolling > .ps-scrollbar-y-rail,
.ps-container:hover > .ps-scrollbar-x-rail:hover,
.ps-container.ps-in-scrolling > .ps-scrollbar-x-rail  {
    background-color: transparent !important;
}


.mdl-tooltip.is-active {
	animation: none;
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

hr {
	margin: 0;
	border-style: dashed;
}

.inline-tooltip-icon {
	color: #4e4e4e;
    position: relative;
    bottom: -6px;
}







//----------------------------------------------------
// # Helpers
//----------------------------------------------------

.font-weight-300 {
	font-weight: 300;
}

.hidden-form-item {
	display: none;
	visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #d0d0d0;
  transition: background-color 0s ease-in-out 5000000s;
}





//----------------------------------------------------
// # Misc
//----------------------------------------------------

.ps-container > .ps-scrollbar-y-rail {
    margin: 4px 0;
}

.small-title {
	font-size: 18px;
	margin: 0;
}


// Scroll to top btn

.scroll-to-top-btn {
	position: fixed;
	right: 32px;
	bottom: 76px;
	z-index: 50;
	display: none;

	&.is-visible {
		display: block;
	}
}


// Main alert messages

.alert {
	padding: 15px 32px;
	color: #fff;
	font-weight: 700;
	position: relative;

	&--success {
		background: #4CAF50;
	}

	&--error {
		background: #F44336; 
	}

	&__close {
		font-size: 18px;
		position: absolute;
		top: 50%;
		margin-top: -11px;
		right: 27px;
		color: #fff;
		cursor: pointer;
	}
}





//----------------------------------------------------
// # Toolbar
//----------------------------------------------------

.toolbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 64px;
	z-index: 10;
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),
				0 1px 1px 0 rgba(0,0,0,.14),
				0 2px 1px -1px rgba(0,0,0,.12);

	
	.icon {
		color: rgba(0,0,0,0.8);
		font-size: 22px!important;
		width: 22px!important;
		height: 22px!important;
		min-width: 22px!important;
		min-height: 22px!important;
		line-height: 22px!important;
	}
}
// <img src="/app/assets/images/logo.png" alt="NeocoreGames Logo" width="171" height="48">

.toolbar__logo {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	width: 240px;
	float: left;

	a {
		display: inline-block;
		width: 171px;
		height: 48px;
	}

	span {
		display: block;
		width: 171px;
		height: 48px;
		background: url('/app/assets/images/logo.png') center center no-repeat;
		background-size: 171px 48px;
	}

	&--square {
		width: 64px;

		span {
			width: 51px;
			height: 48px;
			background: url('/app/assets/images/logo-square.png') center center no-repeat;
			background-size: 51px 48px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.toolbar__separator {
	height: 64px;
	width: 1px;
	background: rgba(0,0,0,.12);
	float: left;
}

.toolbar__navigation-toggle {
	width: 64px;
	height: 64px;
	cursor: pointer;
	float: left;
	padding: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	
	.icon {
		transition: .3s;
	}

	&.active {
		.icon {
			transition: .3s;
			transform: rotate(180deg);
		}
	}
}

.toolbar__spinner {
	float: right;
	width: 64px;
	height: 64px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbar__search-btn {
	width: 64px;
	height: 64px;
	cursor: pointer;
	float: right;
	padding: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbar__search-field {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 241px;
	z-index: 40;
	opacity: 0;
	visibility: hidden;

	&.is-visible {
		opacity: 1;
		visibility: visible;
	}

	.main--collapsed & {
		left: 65px;
	}
	
	input {
		border: none;
		line-height: 64px;
		font-weight: 20px;
		padding: 0 0 0 80px;
		border: none;
		margin: 0;
		width: calc(100% - 80px);
		color: #777;
	}

	.icon__search {
		position: absolute;
		top: 20px;
    	left: 30px;
	}

	.icon__close {
		position: absolute;
		top: 16px;
		right: 26px;
	}
}

.toolbar__results {
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	position: relative;

	&::before {
		position: absolute;
		content: '';
		left: -1px;
		top: 0;
		bottom: 0;
		background: #fff;
		display: inline-block;
		height: 100%;
		width: 1px;
	}

	&-inner {
		max-height: 216px;
		overflow-y: scroll;
	}

	a {
		display: block;
		padding: 15px 35px;
		display: block;
		position: relative;
		text-decoration: none;
		color: #515151;
		font-weight: 400;
		height: calc(54px - 30px);

		.icon {
			color: #424242;
			font-size: 18px !important;
			position: relative;
			top: 3px;
			margin-right: 10px;
			width: 18px !important;
		    line-height: 18px !important;
		    height: 18px !important;
		}

		&:hover,
		&:focus {
			background: #eee;
		}
/*
		&::before {
			content: '';
			width: 8px;
			height: 8px;
			display: inline-block;
			background: $defaultColor;
			position: absolute;
			top: 50%;
		    left: 35px;
		    margin-top: -5px;
		}*/
	}
}

.mdl-spinner--single-color .mdl-spinner__layer-1,
.mdl-spinner--single-color .mdl-spinner__layer-2,
.mdl-spinner--single-color .mdl-spinner__layer-3,
.mdl-spinner--single-color .mdl-spinner__layer-4 {
	border-color: #03A9F4;
}





// Dropdown

.dropdown {
	height: 64px;
	padding: 0 20px;
	cursor: pointer;
	float: right;

	display: flex;
	align-items: center;
	justify-content: center;

	.mdl-menu__container.is-visible + & {
		background-color: rgba(158,158,158, 0.20);
	}
}

.dropdown__inner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown__avatar {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.dropdown__username {
	font-size: 14px;
	white-space: nowrap;
	font-weight: 500;
	letter-spacing: .010em;
	margin: 0 15px;
	text-transform: none;
}





// Dropdown container

.toolbar .mdl-menu__container {

	li {
		padding: 0 25px;
		display: flex;
		align-items: center;
	}

	.mdl-menu__outline {
		border-radius: 0;
	}

	.mdl-menu__title {
		margin-left: 20px;
	}

	.mdl-menu__item {
		line-height: 24px;
		font-size: 15px;
	}

	.material-icons {
		color: rgba(0,0,0,0.54);
	}
}





//------------------------------------------------------------------------------
// # Sidenav
//------------------------------------------------------------------------------

.sidenav {
	width: 240px;
	position: fixed;
	z-index: 5;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 10px 0;
	top: 64px;
	height: calc(100vh - 84px);
	bottom: 0;
	left: 0;
	background: $darkGray;
	// overflow: hidden;

	li {
		list-style: none;
	}
/*
	.has-submenu > .navigation-button {
		background: transparent;
	}
*/
}

.sidenav--collapsed {
	width: 64px;
	transition: all .1s $sidenavAnimationTiming,
				width .1s linear;
	transition-delay: .2s;

	.sidenav__title {
		color: transparent;
		transition: color .1s $sidenavAnimationTiming;
		transition-delay: .2s;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 18px;
			left: 50%;
			height: 1px;
			width: 30px;
			background: #545966;
			transform: translateX(-50%);
			transition: opacity .1s $sidenavAnimationTiming;
			transition-delay: .2s;
			opacity: 1;
		}
	}

	.navigation-button__title,
	.navigation-button__arrow,
	.navigation-button__badge  {
		opacity: 0;
		transition: all .1s $sidenavAnimationTiming;
		transition-delay: .2s;
	}

	.has-submenu.open {
		> .navigation-button {
			background: none !important;
			transition: .005s !important;
			transition-delay: .2s !important;
		}
	}

	.submenu li {
		overflow: hidden;
		max-height: 0;
		transition: .005s !important;
		transition-delay: .2s !important;
	}

	&:hover {
		width: 240px;
		transition: all .3s $sidenavAnimationTiming,
					width .1s linear;
		transition-delay: .2s;

		.sidenav__title {
			color: rgba(255,255,255,.3);
			transition: color .3s $sidenavAnimationTiming;
			transition-delay: .2s;

			&::before {
				opacity: 0;
				transition: opacity .3s $sidenavAnimationTiming;
				transition-delay: .2s;
			}
		}

		.navigation-button__title,
		.navigation-button__arrow,
		.navigation-button__badge {
			opacity: 1;
			transition: all .3s $sidenavAnimationTiming;
			transition-delay: .2s;
		}

		.submenu li {
			overflow: hidden;
			max-height: 200px;
			transition: .005s !important;
			transition-delay: .2s !important;
		}

		.has-submenu.open {
			> .navigation-button {
				background: rgba(0,0,0,.1) !important;
				transition: .005s !important;
				transition-delay: .2s !important;
			}
		}

	}
}
	
.sidenav__title {
	font-size: 12px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 6px;
    cursor: auto;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    position: relative;
    line-height: 32px;
	color: rgba(255,255,255,.3);
	padding-right: 16px;
	padding-left: 21px;
}

.sidenav__navigation {
	margin: 0;
	padding: 0;
	backface-visibility: hidden;


	.has-submenu {
		margin: 0;
		padding: 0;

		&.open {
			background: rgba(0,0,0,.1);	
		}

		.navigation-button__arrow {
			transition: .3s;
		}

		&.open {

			> .navigation-button {
				> .navigation-button__arrow {
					transform: rotate(90deg);
					transition: .3s;
				}
			}		
		}

		&.open .has-submenu {
			background: transparent;
		}
	}

	.submenu {
		margin: 0;
		padding: 0;
		max-height: 0;
		overflow: hidden;
		transition: .7s linear;

		&.open {
			max-height: 1000px;
			transition: .3s linear;
		}

		li {
			list-style: none;
			background: rgba(0,0,0,.1);
		}

		a {
			padding-left: 52px;
			width: calc(100% - 16px - 52px);
		}

		.submenu a {
			padding-left: 62px;
			width: calc(100% - 16px - 62px);
		}
	}

	.has-submenu .submenu li {
		background: transparent;
	}
}

.navigation-button {
	position: relative;
	width: 100%;
	height: 48px;
	line-height: 48px;
	white-space: normal;
	overflow: hidden;
	text-decoration: none;
	color: #fff;
	padding-right: 16px;
	padding-left: 21px;
	width: calc(100% - 16px - 21px);
	font-weight: 700;
	font-size: 13px;
	user-select: none;
	-webkit-user-select: none;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	&:hover,
	&:focus {
		background: rgba(0,0,0,.3);
	}

	&.navigation-button--active {
		// TODO: kiszervezés defaultColor változóba
		background: #37474f;
		color: #fff;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 4px;
			background: $defaultColor;
			display: block;
		}
	}

	.icon {
		margin-right: 15px;
		font-size: 18px;
	    position: relative;
	    top: -1px;
	}

	.navigation-button__arrow {
		margin-right: 0;
		margin-left: auto;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: -15px;
			right: -15px;
			bottom: -15px;
			left: -15px;
		}
	}
}

.navigation-button__badge {
	position: absolute;
	right: 45px;
	top: 50%;
	transform: translateY(-50%);
	min-width: 20px;
	height: 20px;
	background: red;
	border-radius: 20px;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	padding: 0;
	display: inline-block;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	text-shadow: 0 0 3px rgba(0,0,0,0.2);
}





//------------------------------------------------------------------------------
// # Main Container
//------------------------------------------------------------------------------

.inline-header-wrapper {
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	z-index: 30;
}

@supports (position: sticky) {
	.inline-header-wrapper {
		position: sticky;
	}
}

.main--collapsed {
	.main-container {
		left: 64px;
		transition: .3s;
		width: calc(100% - 64px);
	}
}

.main-container {
	position: fixed;
	z-index: 3;
	top: 64px;
	left: 240px;
	overflow-x: hidden;
	overflow-y: scroll;
	height: calc(100vh - 64px);
	transition: .3s;
	width: calc(100% - 240px);
	// transform: scale(1);

	&.animate--out {
		animation-name: main-container-animate-out;
    	animation-duration: .8s;
    	animation-fill-mode: forwards;
	}

	&.animate--in {
		transform: scale(1);
    	animation-duration: 0.8s;
    	animation-fill-mode: forwards;
	}
}

@keyframes main-container-animate-out {
    0%   { 
    	opacity: 1;
    }
    100% { 
    	opacity: 0;
    	transform: translateY(40px);
    }
}

@keyframes main-container-animate-in {
    0%   {
    	opacity: 0;
    	transform: translateY(-40px);
    }
    100% {
    	opacity: 1;
    	transform: translateY(0);
    }
}


#js-data-table {
	&.animate--out {
		animation-name: main-container-animate-out2;
    	animation-duration: .6s;
    	animation-fill-mode: forwards;
	}

	&.animate--in {
		animation-name: main-container-animate-in2;
    	animation-duration: .6s;
    	animation-fill-mode: forwards;
	}
}

@keyframes main-container-animate-out2 {
    0%   { 
    	opacity: 1;
    }
    100% { 
    	opacity: 0;
    	transform: translateX(-40px);
    }
}

@keyframes main-container-animate-in2 {
    0%   {
    	opacity: 0;
    	transform: translateX(40px);
    }
    100% {
    	opacity: 1;
    	transform: translateX(0);
    }
}




//------------------------------------------------------------------------------
// # Page Header
//------------------------------------------------------------------------------

.page-header {
	
	background-color: #f6f6f6;
	color: rgba(255,255,255,1);
	
	position: relative;
}

.page-header--list-view {
	height: 120px;
	margin-top: 48px;
	padding: 0 32px;
	display: flex;
	align-items: center;
	// TODO: Hátteret lekezelni dinamikusra.
	background-image: url(/app/assets/images/login-background.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.page-header--no-margin {
	margin: 0;
}

.page-header--dashboard-view {
	height: 190px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	.page-header-title__title {
		position: absolute;
		top: 32px;
		left: 32px;
		font-size: 38px;
	}

	.page-header-title__dashboard-title {
		position: absolute;
		bottom: 0;
		left: 32px;
		background: rgba(0,0,0,0.8);
		padding: 15px;
		line-height: 1em;
	}
}

.page-header--user-profile {
	height: 270px;
	// TODO: hátteret beállítani HTML-ben.
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 48px;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0.3);
		display: block;
	}
}

.page-header-title {
	margin: 0;
	display: flex;
}

.page-header-title__left {
	> i,
	> span {
		margin-right: 15px;
	}
}

.page-header-title__title {
	margin: 0 0 0 0;
	font-size: 24px;
	font-weight: 400;
	line-height: 1em;
}

.page-header-title__product-count {
	color: rgba(255,255,255,0.7);
	font-size: 12px;
}

.page-header-title__icon {
	font-size: 24px;
	margin-right: 10px;
}

.page-header-title__mail-keys {
	position: absolute;
	bottom: 32px;
	right: 32px;
}

.page-header__order-status {
	font-size: 12px;
    margin-left: 15px;
    padding: 0px 7px !important;
    position: relative;
    top: -5px;
}

.page-header-actions {
	margin-left: auto;
	z-index: 10;
}

.page-header-actions__background-input {
	display: none;
}

.page-header-actions--right-bottom {
	position: absolute;
	right: 32px;
	bottom: 32px;
}

.page-header__quick-actions {
	position: absolute;
	right: 30px;
    // top: -28px;
    bottom: -75px;
    z-index: 10;
	
	.mdl-button {
		margin: 0 5px;
	}
}

.page-header__lang-wrapper {
	display: inline-block;
	float: left;
	width: 66px;
	height: 56px;
	position: relative;
	transition: .3s;

	#btn-translate {
		position: absolute;
		top: 0;
		right: 0;
	}

	.set-form-lang-field {
		position: absolute;
		right: 10px;
    	top: 11px;
    	transition: .3s;
	}

	&.is-open {
		width: 212px;
		transition: .3s;

		.set-form-lang-field-ru {
			right: 165px;
			transition: .3s;
		}

		.set-form-lang-field-de {
			right: 117px;
			transition: .3s;
		}

		.set-form-lang-field-en {
			right: 69px;
			transition: .3s;
		}
	}
}

.page-header-user {
	position: absolute;
	left: 32px;
	bottom: 32px;
	z-index: 10;

	display: flex;
	align-items: center;

	&--product {
		.page-header-user__avatar-image {
			border-radius: 0;
			width: 120px;
			height: 120px;
		}

		.page-header-user__avatar-icon {
			margin-top: -26px;
		}

		.page-header-user__avatar-image-wrapper {
			&.is-error::before {
				border-radius: 0;
			}
		}
	}

	&__avatar {
		position: relative;
	}

	&__avatar-image {
		width: 95px;
		height: 95px;
		border-radius: 95px;
		cursor: pointer;
	}

	&__avatar-help-text {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 5px;
		background: rgba(0,0,0,0.8);
		color: #fff;
		font-size: 13px;
		pointer-events: none;
	}

	&__avatar-image-wrapper {
		position: relative;
		display: inline-block;
		margin-right: 15px;

		&.is-error::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			box-shadow: 0 0 0 2px #F44336 inset;
			border-radius: 95px;
			background: rgba(#F44336, 0.3);
		}
/*
		&.is-error::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background: rgba(#F44336, 0.3);
		}*/

		&.is-error::after {
			content: attr(data-error-text);
		    display: block;
		    position: absolute;
		    right: 0;
		    bottom: 110%;
		    left: 0;
		    color: #fff;
		    // text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
		    font-size: 11px;
		    line-height: 1.3em;
		    text-align: center;
		    text-transform: uppercase;
		    color: #F44336;
		}

	}

	&__avatar-input {
		display: none;
	}

	&__avatar-icon {
		font-size: 34px;
		display: inline-block;
		width: 34px;
		height: 34px;
		color: $defaultColor;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -25px;
		margin-top: -17px;
		pointer-events: none;
		z-index: 10;
	}

	&__row {
		display: flex;
		align-items: center;
	}

	&__name {
		font-size: 36px;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
		display: inline-block;
		position: relative;
		
		> span {
			display: inline-block;
			min-width: 20px;
			min-height: 48px;
			outline: none;
			
			@-moz-document url-prefix() { 
				&::after {
					content: "l";
					color: transparent;
					font-size: 0px;
					width: 0;
					height: 0;
					display: inline;
				}
			}
		}
	}

	&__name-edit {
		color: $defaultColor;
		font-size: 20px;
		position: absolute;
		top: 15px;
		right: -28px;
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
	}

	&__text-badge {
		background: #FFB300;
		display: inline-block;
		font-weight: 500;
		margin-left: 20px;
		font-size: 13px;
		padding: 2px 10px;
		border-radius: 2px;
		box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),
				    0 1px 1px 0 rgba(0,0,0,.14),
				    0 2px 1px -1px rgba(0,0,0,.12);

		text-shadow: 0 0 5px rgba(0,0,0,0.4);
	}

	&__user-type {
		display: inline-block;
		font-weight: 300;
		font-size: 24px;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
	}

	&__avatar-delete {
		font-size: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 95px;
		height: 95px;
		border-radius: 95px;
		color: rgb(255,64,129);
		background: rgba(0,0,0,0.6);
		position: absolute;
		text-decoration: none;
		top: 0px;
		left: 0px;		
		z-index: 101;
		transition: opacity .3s;
		opacity: 0;

		&:hover {
			opacity: 1;
		}
	}

	&__badge {

		&--type-one {

		}

	}
}

.page-header-generic {
	position: absolute;
	left: 32px;
	bottom: 32px;
	z-index: 10;

	display: flex;
	align-items: flex-end;


	&__row {
		display: flex;
		align-items: center;

		&.is-subtitle {
			margin-top: -11px;
		}

		&.is-subshorttitle {
			margin-top: -15px;
		}

		&.is-meta {
			margin-bottom: 7px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__subtitle {
		font-size: 36px;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
		display: inline-block;
		position: relative;
		
		> span {
			display: inline-block;
			min-width: 20px;
			min-height: 48px;
			outline: none;
			
			@-moz-document url-prefix() { 
				&::after {
					content: "l";
					color: transparent;
					font-size: 0px;
					width: 0;
					height: 0;
					display: inline;
				}
			}
		}
	}

	&__title {
		font-size: 28px;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
		display: inline-block;
		position: relative;
		
		> span {
			display: inline-block;
			min-width: 20px;
			min-height: 48px;
			outline: none;
			
			@-moz-document url-prefix() { 
				&::after {
					content: "l";
					color: transparent;
					font-size: 0px;
					width: 0;
					height: 0;
					display: inline;
				}
			}
		}
	}

	&__short_title {
		font-size: 24px;
		margin: 0;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
		display: inline-block;
		position: relative;

		> span {
			display: inline-block;
			min-width: 20px;
			min-height: 48px;
			outline: none;

			@-moz-document url-prefix() {
				&::after {
					content: "l";
					color: transparent;
					font-size: 0px;
					width: 0;
					height: 0;
					display: inline;
				}
			}
		}
	}

	&__text-edit-icon {
		color: $defaultColor;
		font-size: 20px;
		position: absolute;
		top: 15px;
		right: -28px;
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
	}

	&__edit-icon {
		color: $defaultColor;
		font-size: 20px;
		position: absolute;
		top: 15px;
		right: -28px;
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;
	}


	&__meta {
		display: inline-block;
		font-weight: 300;
		font-size: 24px;
		text-shadow: 0 0 10px rgba(0,0,0,0.1);
		position: relative;

		.page-header-generic__edit-icon {
			position: relative;
			top: 3px;
    		right: -7px;
		}

		.mdl-select {
			position: absolute;
			top: 0;
		    left: 0;
		    padding: 0;
		    opacity: 0;
			pointer-events: none;
		}

		select {
			display: none;
		}

	}
	
	.select2-container--default .select2-selection--single {
		border: none;
	}

	.select2-container .select2-selection--single .select2-selection__rendered {
		color: #343434;
	    font-size: 12px;
	    font-weight: 700;
	    padding-left: 10px;
	}

	.select2-container {
		width: 150px !important;
	}

	.select2 {

	}
}

// Kereső

.page-header {
	/*
	.search-panel {
		position: absolute;
		left: 50%;
		top: 20px;
		margin-left: -175px;
		width: 350px;
	}*/
}


.search-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	* {
		will-change: auto;
	}

	.inside {
		width: 380px;
		position: relative;
	}

	.mdl-menu {
		display: none;

		&.is-visible {
			display: inline-block;
		}
	}
	
	.mdl-textfield {
		padding: 0;
		position: relative;
	}

	.mdl-textfield__input {
		font-size: 18px;
		padding: 8px 0 8px 35px;
		border-bottom: 2px solid rgba(255,255,255,0.6);
		width: calc(100% - 40px);
	}

	.mdl-checkbox.is-upgraded {
		padding-left: 44px;
	}

	.mdl-checkbox__focus-helper,
	.mdl-checkbox__box-outline {
		top: 14px;
    	left: 17px;
	}


	&__icon-before {
		font-size: 20px;
	    color: #fff;
	    position: absolute;
	    bottom: 10px;
	    left: 0px;
	}

	&__icon-after {
		position: absolute;
	    bottom: 6px;
	    right: 0px;
	    cursor: pointer;
		display: none;
		visibility: hidden;

	    &.is-visible {
	    	display: inline-block;
	    	visibility: visible;
	    }
	}
}

.search-panel--stand-alone {
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.inside {
		width: 100%;
	}

	.mdl-textfield {
		padding: 19px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
					0 3px 1px -2px rgba(0, 0, 0, 0.2),
					0 1px 5px 0 rgba(0, 0, 0, 0.12);
		background: #fff;
	}

	.mdl-textfield__input {
		border-bottom: none;
		background: #fff;
		font-size: 14px;
    	padding: 0 0 0 35px;
	}

	.search-panel__icon-before {
		color: #767676;
		font-size: 25px;
		bottom: 17px;
    	left: 14px;
	}
	
	.search-panel__icon-after {
	    bottom: 16px;
    	right: 15px;
    }

    .search-panel-tag {
    	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
					0 3px 1px -2px rgba(0, 0, 0, 0.2),
					0 1px 5px 0 rgba(0, 0, 0, 0.12);
		background: #fff;
    }

    .search-panel-tags {
    	margin-top: 16px;
    }
}


.search-panel-tags {
	margin-top: 10px;
	display: none;
	visibility: hidden;

	&.is-visible {
		display: block;
		visibility: visible;
	}
}

.search-panel-tag {
	height: 26px;
	line-height: 26px;
	background: #f6f6f6;
	border-radius: 10px;
	padding: 0 22px 0 10px;
	color: #232323;
	display: inline-block;
	float: left;
	position: relative;
	margin-right: 10px;

	&__title {
		font-size: 12px;
		display: inline-block;
		float: left;
		position: relative;
		padding-right: 6px;
		line-height: 26px;


		&::after {
			content: '';
			height: 12px;
			display: inline-block;
			width: 1px;
			background: #777;
			position: absolute;
			top: 7px;
			right: 0;
		}
	}

	&__close {
		color: #F44336;
		display: block;
		width: 26px;
		height: 26px;
		border-radius: 10px;
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		line-height: 32px;
		cursor: pointer;
		font-size: 12px;

		i {
			font-size: 14px;
		}
	}

	&__action {
		color: $defaultColor;
		text-decoration: underline;
		cursor: pointer;
	}

}

.search-panel-tag__action {
	color: $defaultColor;
	text-decoration: underline;
	cursor: pointer;
}

.search-panel-popovers {

}

.search-panel-popover {
	background: #fff;
	padding: 20px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 30;
	min-width: 200px;
	opacity: 0;
	visibility: hidden;
	transition: .3s;

	transition-property: opacity;
	@include material-animation-fast-out-slow-in($duration:.3s);

	&.is-visible {
		opacity: 1;
		visibility: visible;
	}

	&__btns {
		text-align: center;

		.mdl-button {
			margin: 0 5px;
		}
	}
}

.data-table-wrapper {
	position: relative;
	width: 100%;
}

.data-table-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 15;
	opacity: 0;
	visibility: hidden;
	transition-property: opacity;
	@include material-animation-fast-out-slow-in($duration:.3s);

	&.is-visible {
		opacity: 1;
		visibility: visible;
	}
}






//------------------------------------------------------------------------------
// # Inline Navigation
//------------------------------------------------------------------------------

.inline-navigation {
	height: 56px;
	background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
    padding: 0 32px;
    position: relative;
}

.inline-navigation__menu {
	margin: 0;
	padding: 0;
	float: left;

	li {
		display: inline-block;
		list-style: none;

		&:not(:first-of-type) {
			margin-left: -4px;
		}
	}

	a {
		font-size: 13px;
    	text-align: center;
		white-space: nowrap;
    	position: relative;
    	line-height: 56px;
    	color: rgba(0,0,0,.54);
    	padding: 0 15px;
    	text-transform: none;
    	text-decoration: none;
    	font-weight: 600;
    	display: inline-block;
    	height: 56px;
    	border-radius: 0;

    	&.active {
    		color: rgb(45,50,62);

    		&::before {
    			content: '';
    			position: absolute;
    			right: 0;
    			bottom: 0;
    			left: 0;
    			height: 2px;
    			background: $defaultColor;
    		}
    	}
	}
}







//------------------------------------------------------------------------------
// # Breadcrumb
//------------------------------------------------------------------------------

.breadcrumb {
	background: #fbfbfb;
	border-bottom: 1px solid darken($lightGray, 10%);

	&.fixto-fixed {
		top: 64px !important;
	}
}

.breadcrumb__menu {
	margin: 0;
	padding: 10px 32px;

	li {
		display: inline-block;
		list-style: none;
		margin-right: 10px;
		padding-right: 15px;
		position: relative;
	}


	li:not(:last-of-type)::before {
		content: '/';
		position: absolute;
		top: 0;
		right: 0;
		color: #ccc;
	}

	a,
	span {
		font-size: 12px;
		
	}

	a {
		text-decoration: none;
		color: #7e7e7e;
		font-weight: 600;

		> span {
			color: #7e7e7e;
			margin-right: 5px;
			font-size: 17px;
    		top: 2px;
    		position: relative;
		}

		&:hover,
		&:active {
			color: $defaultColor;

			> span {
				color: $defaultColor;
			}
		}
	}

	span {
		color: #aaa;
	}
}





//----------------------------------------------------
// # Dragula
//----------------------------------------------------

.gu-transit {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50); 
}

.gu-mirror {
	position: relative;
    z-index: 2000;
    pointer-events: none;
}

.dg-disabled {
	.drag-and-drop-column__icon-wrapper {
		display: none !important;
		pointer-events: none;
	}

	.counter-column__counter {
		border-color: #656565 !important;
		background: #656565 !important;
		color: #fff;
	}
}
 
.dg-item {
    position: relative;

    &.gu-mirror {
		.mdl-content-header {
			background: #8797a1;
		}

		.dg-handle--header {
			background: $defaultColor; 
		}
    }

    .dg-handle {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        cursor: move;
        background: rgba(0,0,0, 0.1); 
        padding: 5px;
        font-size: 24px;
        line-height: 24px;
        color: #424242;
        opacity: 0.6;
    }
	
	.dg-handle--header {
		background: transparent;
		width: 36px;
		height: 36px;
		
		margin-right: 15px;
    	cursor: move;
    	line-height: 36px;
    	text-align: center;
    	border-radius: 50px;
    	-webkit-user-select: none;
    	user-select: none;

    	&:hover {
    		background: $defaultColor;
    	}

    	.material-icons {
    		font-size: 24px;
    		margin: 0;
    		line-height: 36px;
    		pointer-events: none;
    	}
	}

    &:hover .dg-handle {
        display: block;
    }
}

.header-tab {
    list-style: none;
    margin: 0;
    padding: 0;
    li { display: inline-block; }
}






//----------------------------------------------------
// # Dashboard
//----------------------------------------------------


.percentlist { 
    .item {
        border-bottom: 1px solid darken($lightGray, 5%);
        @include clearfix();
        padding: 20px 0;
        .percentgraph {
            float: left;
            width: calc(100% - 90px);
            padding: 0 0 0 16px;
            box-sizing: border-box;
        }
        .bar {
            height: 5px;
            position: relative;
            background: lighten($defaultColor, 30%);
            div {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background: $defaultColor;
            }
        }
        .title {
            margin-bottom: 8px;
        }
        .value { 
            float: left;
            width: 90px; 
            text-align: right;
            padding: 0 16px 0 0;
            box-sizing: border-box;
            color: rgba(0,0,0,0.54);
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.clear-input {
	border: 1px solid darken($lightGray, 5%);
	padding: 6px;
}

.commentlist {
	max-height: 374px;
	&__item {
		padding: 10px 16px 5px 16px;
		@include clearfix();
		border-bottom: 1px solid darken($lightGray, 5%);
		&:nth-child(2n+2) { background: #f8f8f8; }
		&:hover {
		    transition-duration: 0.28s;
		    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		    transition-property: background-color;
		    background-color: #efefef;
		}
	}
	&__avatar {
		display: block;
		border-radius: 50%;
		width: 42px;
		height: auto;
		float: left;
		margin: 6px 16px 0 0;
	}
	&__content {
		float: left;
		width: calc(100% - 42px - 16px);
	}
	&__name {
		font-weight: bold;
		margin-bottom: 2px;
	}
	&__date {
		font-weight: 500;
		/*opacity: 0.7;*/
	}
	&__comment {
		font-size: 12px;
		line-height: 14px;
		opacity: 0.5;
		width: 100%;
		height: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 5px;
	}
	&__opp-list { 
		display: none;
	}
	&__item:hover &__opp-list { 
		display: block; 
		span { 
			cursor: pointer;
		}
	}
	.unapprove {
		color: #ff6d00; 
	}
	.approve {
		color: #37ba63;
	}
	.reply {	
		color: #2196f3;
	}
	.spam {
		color: #e91e63;
	}
	.trash {
		color: #ba0d00;
	}
	.pending {
		color: #ff6d00;
	}
}

.activities-list { 
	padding: 16px;
	.ps-container {
		max-height: 308px;
	}
	&__item {
		@include clearfix();
		background: #fafafa;
		margin-bottom: 7px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.pg-box {
		display: block;
		float: left;
		width: 26px;
        height: 26px;
        line-height: 26px;
        font-size: 18px;
		text-align: center;
		color: #fff;
		background: #bac3d0;
		&.pg-box--indigo { background: #659be0; }
		&.pg-box--blue { background: #36c6d3; }
		&.pg-box--red { background: #ed6b75; }
	}
	&__content {
		width: calc(100% - 26px - 100px);
		float: left;
		padding: 3px 8px;
		line-height: 22px;
		box-sizing: border-box;
		color: rgba(0,0,0, 0.8);	
	}
	&__time {
		float: right;
		width: 100px;
		text-align: right;
		padding: 3px 8px;
		line-height: 22px;
		font-style: italic;
		color: rgba(0, 0, 0, 0.3);
		box-sizing: border-box;
	}
	&__button {
			background: #bac3cf;
			color: #fff;
			min-width: auto;
			&:hover {
				background: #bac3cf;
				color: #fff;
			}
		&--yellow {
			background: #f0c419;
			&:hover {
				background: #f0c419;
			}
		}
		&--blue {
			background: #36c6d3;
			&:hover {
				background: #36c6d3;
			}
		} 
		.icon-mail-forward {
			display: inline-block;
			position: relative;
			top: 2px;
			margin-left: 3px;
		}
	}
}
 
.mdl-intervalselect {
	font-size: 16px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    color: rgba(0,0,0, 0.87);
    span {
    	display: inline-block;
    }
    span, i { 
    	vertical-align: middle; 
    }
    input { display: none; }
}



.live-block-search {
	position: relative;

	&__icon {
		position: absolute;
		top: 16px;
    	left: 15px;
    	color: #737373;
	}

	&__input {
		display: block;
		border: none;
		height: 56px;
		width: calc(100% - 20px - 50px);
		line-height: 56px;
		padding: 0 20px 0 50px;
		color: #737373;
	}
}

.form-data-container {
	counter-reset: rows 0;
	position: relative;

	.form-data-row--simple {
		.mdl-textfield__label {
			&::before {
				content: counter(rows, upper-roman) ". ";
			}
		}
	}

	.form-data-row + .form-data-row + .display-helper {
		display: block !important;
	}

	// TODO: Javítás!
	
	.js-form-data-row-reference + .form-data-row {
		.counter-column__counter {
			&::after {
				content: '';
			    position: absolute;
			    top: -202px;
			    left: 50%;
			    height: 200px;
			    margin-left: -2px;
			    width: 4px;
			    background: white;
			    display: block;
			}
		}
	}
	
	&.is-simple-helper {
		overflow: hidden;

		.form-data-row:first-of-type .counter-column__counter {
			&::after {
				content: '';
			    position: absolute;
			    top: -202px;
			    left: 50%;
			    height: 200px;
			    margin-left: -2px;
			    width: 4px;
			    background: white;
			    display: block;
			}
		}

		.form-data-row:nth-last-of-type(2)  .counter-column__counter {
			&::after {
				content: '';
			    position: absolute;
			    bottom: -202px;
			    left: 50%;
			    height: 200px;
			    margin-left: -2px;
			    width: 4px;
			    background: white;
			    display: block;
			}
		}
	}

	&--simple {
		.form-data-row:nth-last-of-type(2) {

			.counter-column__counter {
				&::after {
					content: '';
				    position: absolute;
				    top: 20px;
				    left: 50%;
				    height: 120px;
				    margin-left: -2px;
				    width: 4px;
				    background: white;
				    display: block;
				}
			}
		}
	}

	.display-helper {
		position: absolute;
		top: 0;
		left: -12px;
		bottom: -20px;
		display: none;

		&--simple {
			left: -14px;
			min-width: 20px;
		}

		&::before {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			right: 50%;
			bottom: 0;
			width: 0;
			height: 100%;
			border-right: 1px dashed #d9d9d9;
		}

		.counter-column__counter {
			width: 16px;
			height: 16px;
			border: 2px solid $defaultColor;
			background: #fff;
			border-radius: 50px;
			display: block;
			position: absolute;
			bottom: -5px;
			left: 50%;
			margin-left: -10px;
			line-height: 20px;
			text-align: center;

			.material-icons {
				font-size: 13px;
    			font-weight: 700;
			}
		}
	}
}

.dg-active {
	.form-data-row .drag-and-drop-column__icon {
		color: #777;
	}

	.form-data-row .counter-column__counter {
		border-color: #777;
	}

	.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
	.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
	.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
		color: #777;
	}

	.sp-preview-inner {
		background: #777 !important;
	}

	.js-form-data-add-item {
		background: #777 !important;
	}

	.display-helper .counter-column__counter {
		border-color: #777;
	}
}

.form-data-row {
	counter-increment: rows 1;
	position: relative;
	z-index: 10;
	
	&--simple {
		margin-bottom: 20px;
		
		.counter-column {
			min-width: 20px;
		}
	}

	&--with-image {
		img {
			height: 50px;
			width: auto;
		}
	}

	&.gu-mirror {
		position: relative;

		.counter-column__counter::before {
			content: '';
		}

		.sp-replacer {
			background: #eef8fe;
		}

		.sp-preview-inner {
			background: #777 !important;
		}

		.js-form-data-add-item {
			background: #777 !important;
		}
		
		.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
		.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
		.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
			color: #777;
		}
	
		.counter-column__counter {
			border-color: #777;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 10px;
			left: 0;
			background: #eef8fe;
			display: block;
		}

		.drag-and-drop-column__icon {
			color: #fff;
			z-index: 30;
		}

		.drag-and-drop-column__icon-wrapper {
			background: $defaultColor;
		}
	}
	
	.counter-column,
	.drag-and-drop-column,
	.delete-column {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.drag-and-drop-column {
		&__icon-wrapper {
			position: relative;
			top: -6px;
			left: -6px;
			display: block;
			width: 36px;
			height: 36px;
			border-radius: 30px;
			background: transparent;
			text-align: center;
			cursor: move;
			margin-bottom: 5px;
			-webkit-user-select: none;
			user-select: none;

			&:hover {
				background: $defaultColor;

				.drag-and-drop-column__icon {
					color: #fff;
				}
			}
		}
		&__icon {
			color: $defaultColor;
			user-select: none;
			-webkit-user-select: none;
			position: relative;
			line-height: 36px;
			pointer-events: none;

			&:hover {
				color: #fff;
			}
		}
	}

	.counter-column__counter {
		min-width: 12px;
		height: 16px;
		border: 2px solid $defaultColor;
		background: #fff;
		border-radius: 50px;
		display: block;
		position: relative;
		margin-bottom: 15px;
		text-align: center;
	    padding-left: 2px;
	    padding-right: 2px;

		&::before {
			content: counter(rows);
			font-size: 10px;
			font-weight: 700;
			display: inline-block;

			position: relative;
    		top: -2px;
		}

		&.is-named {
			&::before {
				content: attr(data-name);
				text-transform: uppercase;
			}
		}
	}

	.delete-btn,
	.gray-btn {
		color: #8c8c8c;
		margin-bottom: 15px;
	}
}


.form-data-action-row {
	text-align: center;
	.mdl-button {
		padding: 7px 8px;

		.material-icons {
			position: relative;
			font-size: 20px;
			margin-right: 5px;
		}

		.text {
			position: relative;
		}
	}
}

// Block list fields visibility

.lang-en {
	.form-data-row {
		.mdl-cell {
			display: none;
		}

		.mdl-cell[data-lang-visibility="true"] {
			display: flex;
		}

		.mdl-cell[data-lang-visibility="false"] {
			display: flex;
		}

		.mdl-cell[data-lang-field="en"] {
			display: flex;
		}
	}
}

.lang-de {
	.form-data-row {
		.mdl-cell {
			display: none;
		}

		.mdl-cell[data-lang-container] {
			width: calc(83.3333333333% - 32px) !important;
		}

		.mdl-cell[data-lang-item] {
			width: calc(50% - 32px) !important;
		}

		.mdl-cell[data-lang-visibility="true"] {
			display: flex;
		}

		.mdl-cell[data-lang-visibility="false"] {
			display: none;
		}

		.mdl-cell[data-lang-field="de"],
		.mdl-cell[data-lang-field="en"] {
			display: flex;
		}
	}
}


.lang-ru {
	.form-data-row {
		.mdl-cell {
			display: none;
		}

		.mdl-cell[data-lang-container] {
			width: calc(83.3333333333% - 32px) !important;
		}

		.mdl-cell[data-lang-item] {
			width: calc(50% - 32px) !important;
		}

		.mdl-cell[data-lang-visibility="true"] {
			display: flex;
		}

		.mdl-cell[data-lang-visibility="false"] {
			display: none;
		}

		.mdl-cell[data-lang-field="ru"],
		.mdl-cell[data-lang-field="en"] {
			display: flex;
		}
	}
}


// View Template

.view-template-wrapper {

	.length-counter__display {
		display: none !important;
	}
	
	.mdl-textfield,
	.mdl-radio,
	.mdl-checkbox,
	.mdl-switch-wrapper {
		pointer-events: none;
	}

	.fr-toolbar {
		display: none !important;	
	}

	.mdl-textfield__label {
		color: #000 !important;
		font-size: 12px !important;
		font-weight: 700 !important;
	}

	.mdl-required-marker {
		display: none !important;
	}

	.mdl-textfield__input {
		color: #a1a1a1 !important;
		font-size: 14px !important;
		border-color: #e0e0e0 !important;
	}

	.mdl-content-header {
		background: $defaultColor !important;
	}

	.mdl-textfield--own-focus .mdl-textfield__input {
		border: none !important;
	}

	.mdl-textfield--own-focus::after {
		display: none !important;
	}

	.mdl-radio {
		&.is-checked .mdl-radio__outer-circle {
			border-color: #000 !important;
		}

		.mdl-radio__inner-circle {
			background: #000 !important;
		}

		.mdl-radio__ripple-container .mdl-ripple {
		    background: #000 !important;
		}
	}

	.page-header-user__avatar-image {
		cursor: default;
	}
}

body.lang-en {
	.translate-wrapper > .mdl-cell {
		display: none;
	}
}

body.lang-ru {
	.translatable-area {
		display: none;
	}

	.translate-wrapper {
		.mdl-cell[data-lang="en"] {
			display: block;
		}

		.mdl-cell[data-lang="ru"] {
			display: block;
		}

		.mdl-cell[data-lang="de"] {
			display: none;
		}
	}
}

body.lang-de {
	.translatable-area {
		display: none;
	}
	
	.translate-wrapper {
		.mdl-cell[data-lang="en"] {
			display: block;
		}

		.mdl-cell[data-lang="de"] {
			display: block;
		}

		.mdl-cell[data-lang="ru"] {
			display: none;
		}
	}
}

.translate-wrapper {
	.mdl-cell[data-translate-visibility="false"] {
		display: none !important;
	}

	.mdl-content-header__icon-translate {
		display: none !important;
	}

	.mdl-block-item[data-translatable="true"] .mdl-custom-tabs {
		right: 20px;
	}
}

body.lang-de,
body.lang-ru {
	.translate-wrapper[data-translatable-wrapper="false"] {
		&::before {
			content: attr(data-msg);
			margin: 16px;
			background: #F44336;
			padding: 15px 32px;
			font-weight: bold;
			width: 100%;
			color: #fff;
			display: block;
			@include shadow-4dp();
		}
	}
}

// Google Preview

.google-preview {

	&__title {
		color: #1a0dab;
		margin: 0;
		font-weight: 700;
		line-height: 24px;
		font-size: 16px;
	}

	&__url {
		color: #006621;
		margin: 4px 0;
		line-height: 20px;
	}

	&__description {
		color: #545454;
		margin: 0;
		line-height: 20px;
	}
}


// Length counter

.length-counter {
	position: relative;

	&__display {
		position: absolute;
		right: 0;
		bottom: -3px;
		display: none;
		color: #858585;
		font-size: 12px;
		line-height: 1em;
		white-space: nowrap;

		&.is-visible {
			display: block;
		}

		&.is-limit {
			color: #F44336;
		}
	}

	&__current {

	}

	&__separator {

	}

	&__all {

	}

}


// Custom tooltip

.neo-tooltip {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	background: red;
	padding: 5px;
	display: inline-block;
}

// Separator title 

.separator-title {
	text-align: center;
	overflow: hidden;
	position: relative;
	
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		top: 9px;
		left: 0;
		right: 0;
		background-color: #e0e0e0;
		display: block;
		z-index: 5;
	}

	.mdl-switch-wrapper {
		margin-top: -3px;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		border-bottom: none;
	}

	&__title {
		color: $defaultColor;
		font-weight: 600;
		background: #fff;
		display: inline-block;
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		z-index: 10;
	}
}

.order-status {
	border-radius: 4px;
	padding: 5px 10px;
	display: inline-block;
}

// Simple Accordion

.simple-accordion {
	margin-bottom: 20px;

	&.is-open {
		.simple-accordion__content {
			max-height: 2000px;
			transition: .7s;
		}

		.simple-accordion__title-arrow  {
			transform: rotate(180deg);
			transition: .3s;
		}
	}

	&__title{
		padding: 12px 20px;
	    margin: 0 35px 0 35px;
	    background: #eee;
	    font-weight: 700;
	    height: 20px;
	    position: relative;
	}

	&__title-arrow {
		width: 44px;
	    height: 44px;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    line-height: 44px;
	    text-align: center;
	    font-size: 20px;
	    cursor: pointer;
	    transition: .3s;
	}

	&__content {
		max-height: 0;
		overflow: hidden;
		transition: .7s;
	}
}


.dg-handle {
	display: none !important;
}

.ps-container {
    > .ps-scrollbar-x-rail {
        z-index: 1100;
        height: 12px !important;
        > .ps-scrollbar-x {
            border-radius: 0;
            height: 8px !important;
            top: 2px !important;
        }
    }
    > .ps-scrollbar-y-rail {
        z-index: 1100;
        width: 12px !important;
        > .ps-scrollbar-y {
            border-radius: 0;
            width: 8px !important;
            right: 2px !important;
        } 
    }
}

.style {
	&--color-blue {
		color: $defaultColor;
	}
	&--color-green {
		color: #A5DC86;
	}
	&--color-green-dark {
		color: #00C853;
	}
	&--color-red {
		color: #F27474;
	}

	&--bold {
		font-weight: 700;
	}
}

.fields-disabled-wrapper {

	.mdl-textfield,
	.mdl-radio,
	.mdl-checkbox,
	.mdl-switch-wrapper {
		pointer-events: none;
	}

	.mdl-textfield__label {
		color: #a1a1a1 !important;
	}

	.mdl-textfield__input {
		color: #a1a1a1 !important;
		border-color: #e0e0e0 !important;
	}

	.mdl-checkbox__label {
		color: #a1a1a1 !important;
	}

	.mdl-checkbox {
		.mdl-checkbox__box-outline {
			border-color: #a1a1a1 !important;
		}

		&.is-checked .mdl-checkbox__tick-outline {
			background: #a1a1a1 !important;
		}

		.mdl-checkbox__ripple-container .mdl-ripple {
			background: #a1a1a1 !important;
		}
	}

	.mdl-radio {
		&.is-checked .mdl-radio__outer-circle {
			border-color: #000 !important;
		}

		.mdl-radio__inner-circle {
			background: #000 !important;
		}

		.mdl-radio__ripple-container .mdl-ripple {
			background: #000 !important;
		}
	}

}