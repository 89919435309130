
//--------------------------
// # Grid modifications
//--------------------------

////////// Large Desktop //////////

@media (min-width: $grid-l-desktop-breakpoint) {
  // Define order override classes.
  @for $i from 1 through $grid-max-columns {
    .mdl-cell--order-#{$i}-l-desktop.mdl-cell--order-#{$i}-l-desktop {
      order: $i;
    }
  }

  // Define partial sizes for all numbers of columns.
  @for $i from 1 through $grid-desktop-columns {
    .mdl-cell--#{$i}-col,
    .mdl-cell--#{$i}-col-l-desktop.mdl-cell--#{$i}-col-l-desktop {
      @include partial-size($i, $grid-desktop-columns, $grid-desktop-gutter);
    }
  }

  // Define valid desktop offsets.
  @for $i from 1 through ($grid-desktop-columns - 1) {
    .mdl-cell--#{$i}-offset,
    .mdl-cell--#{$i}-offset-l-desktop.mdl-cell--#{$i}-l-offset-desktop {
      @include offset-size($i, $grid-desktop-columns, $grid-desktop-gutter);
    }
  }
}


////////// Extra Large Desktop //////////

@media (min-width: $grid-x-l-desktop-breakpoint) {

  // Define order override classes.
  @for $i from 1 through $grid-max-columns {
    .mdl-cell--order-#{$i}-x-l-desktop.mdl-cell--order-#{$i}-x-l-desktop {
      order: $i;
    }
  }

  // Define partial sizes for all numbers of columns.
  @for $i from 1 through $grid-desktop-columns {
    .mdl-cell--#{$i}-col,
    .mdl-cell--#{$i}-col-x-l-desktop.mdl-cell--#{$i}-col-x-l-desktop {
      @include partial-size($i, $grid-desktop-columns, $grid-desktop-gutter);
    }
  }

  // Define valid desktop offsets.
  @for $i from 1 through ($grid-desktop-columns - 1) {
    .mdl-cell--#{$i}-offset,
    .mdl-cell--#{$i}-offset-x-l-desktop.mdl-cell--#{$i}-offset-x-l-desktop {
      @include offset-size($i, $grid-desktop-columns, $grid-desktop-gutter);
    }
  }
}