/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";


.mdl-animation--default {
  transition-timing-function: $animation-curve-default;
}

.mdl-animation--fast-out-slow-in {
  transition-timing-function: $animation-curve-fast-out-slow-in;
}

.mdl-animation--linear-out-slow-in {
  transition-timing-function: $animation-curve-linear-out-slow-in;
}

.mdl-animation--fast-out-linear-in {
  transition-timing-function: $animation-curve-fast-out-linear-in;
}
