.image-upload-wrapper {
	position: relative;
	text-align: center;

	&.is-error {
		.image-upload-wrapper__error {
			display: block;
		}
	}

	&__delete-icon {
		color: #F44336;
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 40;
		cursor: pointer;
	}

	&__play {
		z-index: 100000;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-left: -31px;
	    margin-top: -31px;
	    color: rgba(255, 255, 255, 0.9);
    	text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

    	&:hover {
    		color: #fff;
    	}

	    i {
	    	font-size: 62px;
	    }
	}

	&__error {
		display: none;
	}

	&__image {
		width: 100%;
		height: auto;
		cursor: pointer;
	} 

	&__help-text {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 8px;
		background: rgba(0,0,0,0.8);
		color: #fff;
		font-size: 13px;
		pointer-events: none;
		text-align: center;
	}

	&__image-wrapper {
		position: relative;
		display: inline-block;
		margin-right: 15px;
		width: 100%;

		&.has-file {
			width: auto;
		}

		&.is-error::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			box-shadow: 0 0 0 2px #F44336 inset;
			border-radius: 95px;
		}

		&.is-error::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background: rgba(#F44336, 0.3);
		}

		&:hover {
			.media-download__ext,
			.image-upload-wrapper__downloads {
				opacity: 1;
			}
		}
	}

	&__input {
		display: none;
	}

	&__icon {
		font-size: 34px;
		display: inline-block;
		width: 34px;
		height: 34px;
		color: $defaultColor;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -25px;
		margin-top: -17px;
		pointer-events: none;
		z-index: 10;
	}

	&__downloads {
		display: block;
        position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0,0,0,0.3);
		opacity: 0;
		transition: opacity 0.3s;
	}

	.media-download {
        width: 60px;
        margin: 0 10px;
        transition: opacity .3s;
        
        &__ext {
            font-size: 13px;
            color: rgba(255,255,255, 0.85);
            background-color: transparent;
            padding: 2px;
            display: inline-block;
            opacity: 0;
            transition: opacity background-color .3s;
        }

        &:hover {
            .media-download__ext {
				color: white;
            }
        }
	}
}

.media-file-upload {
	.mdl-textfield__label,
	.mdl-textfield__input {		
		width: calc( 100% - 105px );
	}

	.ghost-btn {
		margin-top: -5px;
		cursor: pointer;
		font-weight: 500;
	}

	&.is-delete {
		.mdl-textfield__label,
		.mdl-textfield__input {
			width: calc( 100% - 100px );
		}
	}
}

.hover-state-btn {
    svg {
        max-width: 100%;
        height: auto;
    }
    .normal-state {
        opacity: 1;
        transition: opacity .3s;
    }
    .hover-state {
        opacity: 0;
        transition: opacity .3s;
    }
    &:hover {
        .normal-state {
            opacity: 0;
            transition: opacity .3s;
        }
        .hover-state {
            opacity: 1;
            transition: opacity .3s;
        }
    }
}