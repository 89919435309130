/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";

.mdl-mini-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  padding: ($padding * 2) $padding;

  color: $footer-color;
  background-color: $footer-bg-color;

  &:after {
    content: '';
    display: block;
  }

  & .mdl-logo {
    line-height: $footer-btn-size;
  }
}

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: flex;
  flex-flow: row nowrap;

  list-style: none;

  margin: 0;
  padding: 0;

  & li {
    margin-bottom: 0;
    margin-right: $padding;

    @media screen and (min-width: 760px) {
      line-height: $footer-btn-size;
    }
  }

  & a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
}

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  order: 0;
}

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  order: 1;
}

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: $footer-btn-size;
  height: $footer-btn-size;

  padding: 0;
  margin: 0;

  background-color: $footer-button-fill-color;

  border: none;
}
