// Gallery Tab

.gallery-dropzone {
	display: none;

	&.is-active {
		display: block;
	}
}

.product-image-toolbar {
	
}

.gallery-grid {
	position: relative;
	margin-left: -10px;
	margin-right: -10px;
	margin-top: 10px;

	&:empty {
		margin-top: 0;
	}

	input[type="checkbox"]:checked + label {
		.gallery-grid-item__overlay {
			display: block;
		}
	}
	
	input[type="checkbox"]:checked + label:hover {
		.gallery-grid-item__remove {
			display: inline-block;
		}

		.gallery-grid-item__tick {
			display: none;
		}

		.gallery-grid-item__select-icon {
			background: #fff;

			i {
				color: #ff5722;
			}
		}
	}

}

.gallery-grid-item {
	width: calc(100% / 4 - 20px);
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 20px;
	float: left;
	position: relative;

	&:hover {
		.gallery-grid-item__zoom {
			display: inline-block;
		}

		.gallery-grid-item__dd-handle {
			display: inline-block;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: transparent;
		pointer-events: none;
		display: none;
		z-index: 1;
		border: 4px solid #ff5722;
	}

	&__select-icon {
		width: 26px;
		height: 26px;
		position: absolute;
		top: -4px;
		right: -4px;
		background: #ff5722;
		text-align: center;
		line-height: 26px;
		border: 4px solid #ff5722;

		i {
			color: #fff;
			font-size: 16px;
			position: relative;
		    top: 4px;
    		margin-right: 0px;
		    margin-right: 0px;
		}

		.gallery-grid-item__remove {
			display: none;
			font-size: 22px;
		    position: relative;
		    top: 2px;
		}
	}

	

	&__zoom {
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		text-shadow: 0 0 15px rgba(0,0,0,.3);
		margin-left: -26px;
    	margin-top: -22px;
    	display: none;

    	&:hover {
    		opacity: .8;
    	}

    	i {
    		font-size: 50px;
    	}
	}

	&__dd-handle {
		position: absolute;
	    top: 14px;
    	left: 10px;
	    background: transparent;
	    padding: 5px;
	    line-height: 20px;
	    color: #fff;
	    opacity: 1;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    z-index: 3;
	    display: none;

	    &:hover {
	    	opacity: .7;
	    }

	    span {
	    	line-height: 20px;
	    	font-size: 35px;
	    	cursor: move;
	    }
	}
}


// Metacritic

.metacritic-item {
	background: #73e1a1;
	border-radius: 4px;
	width: 110px;
	height: 110px;
	float: left;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	&__inside {
		text-align: center;
		color: #fff;
	}

	&__title {
		display: block;
		font-size: 16px;
		line-height: 16px;
	}

	&__score {
		display: block;
		font-size: 60px;
		font-weight: 700;
		line-height: 60px;
	}
}

// Related Product

.related-products {
	margin-bottom: 20px;
	position: relative;

	&__items {
		display: inline-block;
	}

	/*

	&__select {
		position: absolute;
		top: 0;
		left: 105%;
		z-index: 99;
	}

	*/

	/*
	.select2-container {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	.select2-container,
	.select2-selection {
		height: 60px !important;
	}

	.select2-selection__choice {
		display: none !important;
	}

	.select2-search--inline {
		float: none;

		input {
			display: block;
			height: 60px;
			padding: 0 20px !important;
			width: 380px !important;
			background: #fff !important;
		}
	}
	
	.select2-selection__rendered::before {
		display: none !important;
	}
*/ 
}

.related-product {
	width: 60px;
	height: 60px;
	display: inline-block;
	margin-right: 15px;
	background-size: cover;
	background-repeat: no-repeat;

	&__add-new {
		background: #00c853;
		display: inline-block;
		width: 60px;
		height: 60px;
		position: relative;
		cursor: pointer;

		&::before {
			content: '';
			display: block;
			position: absolute;
			background: #fff;
			width: 6px;
			height: 30px;
			position: absolute;
			top: 15px;
			left: 27px;
		}

		&::after {
			display: block;
			content: '';
			position: absolute;
			background: #fff;
			height: 6px;
			width: 30px;
			position: absolute;
			left: 15px;
			top: 27px;
		}
	}
}


// Related product select

.select2-results__option {
	.img-preview {
		display: block;
		width: 40px;
		height: 40px;
		margin-right: 10px;
		border-radius: 40px;
	}

	.preview-wrapper {
		display: flex;
		align-items: center;
	}

	.preview-select-text {
		font-weight: 700;
		color: #5a5a5a;
	}
}

.select2-results__option--highlighted {
	.preview-select-text {
		color: #fff;
	}
}

.product-title-sales-percent {
	font-weight: bold;
	color: #e91e63;
}
