.ps-container {
    position: relative;
}
.picker__select--month,
.picker__select--year {
    font-size: 12px;
    margin-top: 0;
    height: 2.4em;
    line-height: 20px;
}
.mdl-textfield__required {
    visibility: visible;
    color: rgba(255, 0, 0, 0.4);
}
.mdl-textfield--half-width {
    width: calc(50% - 15px);
    float: left;
}
span.x {
    width: 20px;
    float: left;
    margin: 25px 5px 0 5px;
    display: inline-block;
    text-align: center;
}
.mdl-textfield .mdl-slider-holder {
    position: absolute;
    width: 100%;
    bottom: 11px;
    left: 0;
}
.mdl-slider__background-flex {
    width: 100%;
    margin: 0;
}
.mdl-slider {
    width: 100%;
    margin: 0;
}
.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
    background: #fff;
}
.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
    background: #fff;
}




/*
    2016.10 - 
*/

.xxx {}



/*
    DASHBOARD OVERRIDEs
*/




/*
    TOOLBAR
*/

.toolbar {
    &__notifications-btn {
        width: 64px;
        height: 64px;
        cursor: pointer;
        float: right;
        padding: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        .icon {
            font-size: 26px !important;
            width: 26px !important;
            height: 26px !important;
            min-width: 26px !important;
            min-height: 26px !important;
            line-height: 26px !important;
        }
    }
    &__quicklinks {
        float: left;
        a {
            display: block;
            float: left;
            text-decoration: none;
            .icon {
                width: 50px !important;
                font-size: 16px !important;
                line-height: 64px !important;
                height: 64px !important;
                display: inline-block;
                text-align: center;
                color: #757575;
                font-style: normal;
            }
        }
    }

}



/*
    NOTIFICATIONs
*/

.notifications-dropdown {
    padding-left: 10px; 
    padding-right: 10px;
    max-width: 340px;
    min-width: 280px;
    @media (min-width: $small-screen-min){
        width: 340px;
    }
    .mdl-tabs__tab-bar {
        @include clearfix();
        margin: 0 0 10px 0;  
        height: 40px;
        a { 
            width: 50%; 
            box-sizing: border-box;
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
        }
    }
    &__message-counter {
        font-size: 12px;
        line-height: 14px;
        padding: 0 16px;
    }
    &__mark-all-read { 
        font-size: 12px;
        line-height: 14px;
        text-align: right; 
        color: #2196f3;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 0 10px 0;
        padding: 0 16px;

        &.is-disabled {
            color: #aaa;
            pointer-events: none;
        }

        &:hover { text-decoration: none; }
    }
}

.notifications-messages {


    &__container {
        height: 320px;
    }
    &__item {
        padding-top: 0;
        margin-bottom: 1px;
        &:hover {
            transition-duration: 0.28s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-property: background-color;
            background-color: #efefef;
        }

        a {
            display: flex;
            align-items: center;
            padding-right: 40px;
        }
    }
    &__avatar,
    &__avatar-monogram {
        display: block;
        border-radius: 50%;
        width: 42px;
        float: left;
        margin: 2px 16px 0 10px;
    }
    &__avatar {
        height: 42px;
    }
    &__avatar-monogram {
        height: 42px;
        line-height: 42px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background-color: #03a9f4;
    }
    &__content {
        float: left;
        width: calc(100% - 42px - 16px - 10px);
        
        padding: 10px 5px 10px 5px;
        box-sizing: border-box;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background: darken($lightGray, 5%);
        }
    }
    &__container .notifications-messages__item:last-of-type &__content:after  {
        display: none;
    }
    &__title {
        display: inline-block;
        font-weight: bold;
        margin-bottom: 2px;
        &.unread {
            color: #2196f3 !important;
        }
    }
    &__comment {
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.5);
    }

    &__date {
        font-size: 10px;
        display: block;
    }

    &__icon-wrapper {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
        margin: 10px 16px 10px 10px;
        background: $defaultColor;
    }
}

.notification-item  {
    opacity: 0.5;
    position: relative;

    &.is-unread {
        opacity: 1;

        .marker-unread {
            z-index: 5;
            top: 0;
            left: 0;

            .read-it-marker {
                width: 9px;
                height: 9px;
            }
        }
        .marker-read {
            z-index: 2;
            top: 1px;
            left: 1px;

            .read-it-marker {
                width: 7px;
                height: 7px;
            }
        }
    }

    &__marker {
        position: absolute;
        right: 25px;
        top: 50%;
        margin-top: -5px;
        cursor: pointer;
        width: 9px;
        height: 9px;
    }

    .marker-unread {
        z-index: 2;
        position: absolute;
        top: 1px;
        left: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        .read-it-marker {
            width: 7px;
            height: 7px;
        }
    }
    .marker-read {
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.3;

        .read-it-marker {
            width: 9px;
            height: 9px;
        }
    }
}

.read-it-marker {
    width: 9px;
    height: 9px;
    display: inline-block;
    border-radius: 9px;
    transition: background-color .3s;

    &.is-read {
        background-color: #414047;
    }

    &.is-unread {
        background-color: #69686c;
    }

    &:hover {
        background-color: $defaultColor;
        transition: background-color .3s;
    }
}

/*
    MDL OVERRIDES
*/

.mdl-button {
    .mdl-badge[data-badge]:after {
        background: #d32f2f;
        min-width: 12px;
        width: auto;
        height: 16px;
        border: 2px solid #fff;
        top: -4px;
        right: -8px;
        padding: 0 2px;
    }
}


/*
    MDL ADDITIONALS
*/

.mdl-tabs__tab { cursor: pointer; }

.mdl-tabs__tab-bar { 
    &--basic {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e8e8e8;
        box-shadow: none;
    }
    &--light {
        margin: 0;
        padding: 0;
        box-shadow: none;
        .mdl-tabs__tab {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            color: rgba(0,0,0, 0.87);
            &.is-active {
                color: rgba(0,0,0, 0.3) !important;
                &:after { display: none !important; }
            }
        }
    }
    &--align-right {
        justify-content: flex-end;
    }
}

.mdl-aligner--center {
    display: flex;
    align-items: center;
}

.mdl-menu--with-flip {
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.14);
    /*
    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 10px 5px;
        border-color: transparent transparent #fff transparent;

        position: absolute;

        top: -10px;
        right: 20px;
    }
    */
}


