.ghost-btn {
	height: 32px;
	line-height: 32px;
	border-width: 1px;
	border-style: solid;
	border-radius: 2px;
	padding-left: 10px;
	padding-right: 10px;
	text-decoration: none;
	display: inline-block;

	&__icon {
		font-size: 18px;
	    position: relative;
	    top: 5px;
	    margin-right: 5px;
	}

	&--white {
		border-color: #fff;
		color: #fff;

		&:hover,
		&.is-active {
			background: #fff;
			color: #000;
		}
	}

	&--orange {
		border-color: #ff5722;
		color: #ff5722;

		&:hover,
		&.is-active {
			background: #ff5722;
			color: #fff;
		}
	}

	&--pink {
		border-color: #e91e63;
		color: #e91e63;

		&:hover,
		&.is-active {
			background: #e91e63;
			color: #fff;
		}
	}

	&--red {
		border-color: #f44336;
		color: #f44336;

		&:hover,
		&.is-active {
			background: #f44336;
			color: #fff;
		}
	}

	&--black {
		border-color: #111111;
		color: #111111;

		.ghost-btn__icon {
			color: #03a9f4;
		}

		&:hover,
		&.is-active {
			background: #111111;
			color: #fff;
		}
	}
}