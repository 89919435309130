
.dashboard {
    &__counter-box {

        .mdl-spinner {
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .daterangepicker {
            display: none;
        }

        .daterangepicker[style] {
            display: block;
        }

        .mdl-menu {
            min-width: 180px;
        }

        &.is-right-spinner-position {
            .mdl-spinner {
                position: absolute;
                right: 45px;
                left: auto;
                top: 15px;
            }
        }

        &.is-loading {
            position: relative;

            .mdl-intervalselect {
                pointer-events: none;
                opacity: .5;
            }

            .counter {
                color: #aaa !important;
            }
        }

        .header {
            @include clearfix();
            padding: 16px 12px 16px 26px;
            .title { 
                font-size: 16px; 
                font-weight: 500;
                display: inline-block;
            }
            .mdl-button--right {  
                float: right;
                width: 24px;
                min-width: 24px;
                height: 24px; 
                .material-icons {
                    font-size: 18px;
                    width: 24px;
                    line-height: 24px; 
                }
            }
        }
        .content {
            padding: 16px 26px 36px 26px;
            .counter { 
                font-size: 4vw;
                line-height: 4vw;
                text-align: center;
                font-weight: 500; 
                margin-bottom: 10px;

                &--small {
                    font-size: 58px;
                    line-height: 58px;
                }

                &--responsive-typo {
                    font-size: 30px;
                    line-height: 1.3em;

                    @media (min-width: $medium-screen-min) {
                        font-size: calc(100vw / 40);
                    } 
                }
            }
            .counter-info { 
                text-align: center; 
                font-weight: bold;
                color: rgba(0,0,0,.54);
                font-size: 16px;
            }
        }
        .footer {
            padding: 20px 26px; 
            color: rgba(0,0,0,.54);
            border-top: 1px solid darken($lightGray, 5%);
            background: $lightGray;
        }
        &.blue .counter { color: #03a9f4; }
        &.red .counter { color: #f44336;}
        &.orange .counter { color: #ff9800; }
        &.gray .counter { color: #607d8b; }
    }

    &__box {
        &.is-loading {
            .content {
                filter: grayscale(100%);
                opacity: 0.6;
            }

            .mdl-spinner {
                display: block !important;
            }
        }

        .header {
            // border-bottom: 1px solid darken($lightGray, 5%);

            border-bottom: 1px solid #e1e1e1;
            padding: 16px 16px;
            .title { 
                font-size: 18px; 
                font-weight: 500;
                line-height: 20px;
                padding: 8px 0;
                display: inline-block;
                text-transform: uppercase;
                position: relative;

                .mdl-spinner {
                    position: absolute;
                    top: 4px;
                    right: -40px;
                    display: none;
                    color: transparent !important;
                }

                .mdl-spinner--single-color.is-white .mdl-spinner__layer-1, .mdl-spinner--single-color .mdl-spinner__layer-2, .mdl-spinner--single-color .mdl-spinner__layer-3, .mdl-spinner--single-color .mdl-spinner__layer-4 {
                    border-color: #fff;
                }
            }

            .mdl-button.is-white i {
                color: #fff;
            }

            .mdl-button.is-black i {
                color: #000;
            }

            .title-icon {
                color: #fff;
                position: relative;
                bottom: -7px;
                font-size: 26px;
            }
        }
        .header-tab { 
            text-align: right; 
            margin: 0;
            padding: 0;
            a {
                font-weight: 700;
            }
            a.active { color: rgba(0,0,0,.3); }
        }
        .content__inner-default {
            padding: 16px;
        }
        .footer {
            border-top: 1px solid darken($lightGray, 5%);
            padding: 20px 16px;
            font-size: 12px;
            opacity: 0.54;
        }
        .pager {
            &__selector { padding-left: 6px; }
            &__selector,
            &__navigation { 
                line-height: 32px; 
                vertical-align: middle;
            }
        }
        .mdl-tabs__tab-bar {
            height: 36px;
            .mdl-tabs__tab {
                line-height: 36px;
                height: 36px;
            }
        } 
        #chart-main {
            overflow-x: hidden;
        }
    }
}

.mr5 {
    margin-right: 5px;
}


// -------------------------
//  Dashboard Main Box
// -------------------------

.dashboard-label {
    height: 22px;
    line-height: 22px;
    padding: 0 7px;
    border-radius: 2px;
    color: #fff;
    background: #999;
    font-size: 12px;
    display: inline-block;

    &.is-pink {
        background: #e91e63;
    }
}

.dashboard-title-label {
    position: relative;
    top: -2px;
    margin-left: 15px;
}

.dashboard__box {
    &.is-blue {
        .header {
            background: #24b4f5;
            border: none;

            .title {
                color: #fff;
            }
        }

        .header-tab {
            a {
                color: #fff;

                &.active { color: rgba(255,255,255,.7); }
            }
        }
    }

    .header { 
        .select2-container { 
            width: auto !important;
            display: inline-block !important; 
            .select2-selection--single {
                border-bottom: none;
            }
        }
    }
    .footer {
        .select2-container { 
            width: 50px !important; 
            display: inline-block !important;
            margin-left: 10px;
            .select2-selection--single {
                border-bottom: none;
            }
        }
    }
}



.neo-tabs {
    display: block;
    width: 100%;
}

.neo-tabs__tab-bar {
    display         : flex;
    flex-direction  : row;
    justify-content : flex-start; 
    align-items     : center;

    height          : 48px;
    padding         : 0 0 0 0;
    margin          : 0;
    border-bottom   : 1px solid $tab-border-color;
}

.neo-tabs__tab {
    margin: 0;
    border: none;
    padding: 0 24px 0 24px;

    float: left;
    position: relative;
    display: block;

    text-decoration: none;
    height: 48px;
    line-height: 48px;

    text-align: center;
    font-weight: 700;
    font-size: $layout-tab-font-size;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.54);
    overflow: hidden;

    &.is-active {
        color: #2d323e;
    }
}

.neo-tabs__tab.is-active::after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0px;
    left: 0px;
    position: absolute;
    background: $tab-highlight-color;
    animation: border-expand 0.2s cubic-bezier(0.4, 0.0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0.0, 1, 1);
}

.td-align--center {
    text-align: center;
}

.micro-data-table {
    width: 100%;
    color: #24b4f5;
    // table-layout: fixed;
    border-collapse: collapse;
    font-weight: 600;

    .mdl-button {
        color: #737373;
    }

    th {
        color: #565656;
    }

    td,
    th {
        padding: 16px 20px;
    }

    td,
    th {
        border-bottom: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;

        &:last-child {
            border-right: none;
        }
    }

    tr:first-child {
        td {
            border-top: 0;
        }
    }
}

.dashboard-empty-msg {
    padding: 15px 32px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
    position: relative;
    background: #efefef;
    font-style: italic;
    height: 200px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 64px);
}

#top5Chartcontainer-wrapper {
    margin: 50px 40px;
}

#flowsChartContainer-wrapper {
    margin: 40px;
}

.box-simple-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #e1e1e1;

    &__left {
        width: 50%;
        text-align: center;
        padding: 25px 10px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 10px;
            right: 0;
            bottom: 10px;
            width: 1px;
            height: calc(100% - 20px);
            background: #e1e1e1;
        }
    }

    &__right {
        width: 50%;
        text-align: center;
        padding: 25px 10px;
    }

    &__title {
        font-size: 36px;
        color: #606060;
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-size: 15px;
        color: #606060;
        display: block;
        text-align: center;
    }
}


.box-extended-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f3f5;
    padding: 15px;
    margin: 25px;

    &__item {
        width: 20%;
        text-align: center;
        margin: 0 25px;
        position: relative;
    }

    &__title {
        height: 32px;
        line-height: 32px;
        padding: 0 20px;
        border-radius: 2px;
        color: #fff;
        background: #999;
        font-size: 14px;
        display: block;
        font-weight: 600;
        margin-bottom: 10px;

        &.is-blue {
            background: #24b4f5;
        }

        &.is-green {
            background: #38b63c;
        }
    }

    &__subtitle {
        font-size: 15px;
        color: #606060;
        display: block;
        text-align: center;
        font-weight: 700;
    }
}

.padding-hack {
    padding-bottom: 1px;
}

.daterangepicker {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    width: 550px !important;
    border-radius: 0 !important;
    border: none !important;

    &::before,
    &::after {
        display: none !important; 
    }
    
    .daterangepicker_input .input-mini {
        display: none;
    }

    .material-icons {
        position: relative;
        top: 3px;
    }
}

.mdl-menu__custom-date {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #24b4f5 !important;
}

.mini-graphs {
    background: #fafafa;
    border-top: 1px solid #e1e1e1;
    padding: 0;
    margin: 40px -15px -15px -15px;

    .mdl-cell {
        margin-top: 20px;
    }

    .highcharts-background {
        fill: #fafafa;
    }
}

.graph-title {
    font-size: 16px;
    color: #7d7d7d;
    margin: 0 0 10px 10px;
}

.graph-data {
    font-size: 26px;
    color: #212121;
    margin: 0 0 0 10px;
}

.daterangepicker {
    display: none;
}

.daterangepicker[style] {
    display: block;
}

.mdl-menu.is-date {
    min-width: 180px;
}