
//----------------------------------------------------
// # MDL modifications
//----------------------------------------------------



//---------------------------
//
// TABLE OF CONTENT 
//
// # Typo
// # Generic
// # Tabs
// # Form
// # MDL box header
// # Postfix label
//
//---------------------------


// Will-change hack

[class^="mdl"]:not(.mdl-button) {
	will-change: contents;
}

.mdl-button {
	will-change: auto;
}

.mdl-tooltip {
	display: none;
}

/*
* {
	will-change: contents, tarnsform;
}
*/



//----------------------------------------------------
// # Typo
//----------------------------------------------------

.mdl-uppercase { 
	text-transform: uppercase; 
}

.mdl-text-normal { 
	text-transform: none; 
}

.mdl-text-right {
	text-align: right;
}





//----------------------------------------------------
// # Generic
//----------------------------------------------------

.mdl-radio__outer-circle fieldset[disabled] .mdl-radio,
.mdl-radio.is-disabled .mdl-radio__outer-circle {
    border: 2px solid rgba(0,0,0, 0.26) !important;
}

.mdl-fs--16 {
	font-size: 16px;
}

.mdl-float--right {
	float: right;
}

.mdl-menu__outline {
	box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),
				0 4px 5px 0 rgba(0,0,0,.14)
				0 1px 10px 0 rgba(0,0,0,.12);
}

.mdl-padding--20 {
	padding: 20px;
}

.mdl-padding--40 {
	padding: 40px;
}

.mdl-padding--0 {
	padding: 0;
}

.mdl-margin--right--0 {
	margin-right: 0;
}

.mdl-margin--left--0 {
	margin-left: 0;
}

.mdl-margin--right--20 {
	margin-right: 20px;
}

.mdl-margin--bottom--10 {
	margin-bottom: 10px;
}

.mdl-margin--bottom--30 {
	margin-bottom: 30px;
}

.mdl-margin-minus-16 {
	margin-left: -16px;
	margin-right: -16px;
}

.mdl-padding--bottom--0 {
	padding-bottom: 0;
}

.mdl-padding--top--0 {
	padding-top: 0;
}

.mdl-padding--0 {
	padding: 0;
}

.mdl-padding--top--20 {
	padding-top: 20px;
}

.mdl-padding--bottom--20 {
	padding-bottom: 20px;
}

.mdl-button--no-hover:hover {
	background: none;
}

.mdl-margin--0 {
	margin: 0;
}

.mdl-margin--top--0 {
	margin-top: 0 !important;
}

.mdl-margin--top--20 {
	margin-top: 20px !important;
}

.mdl-margin--top--10 {
	margin-top: 10px;
}

.mdl-margin--bottom--0 {
	margin-bottom: 0;
}

.mdl-margin--top--bottom--0 {
	margin-top: 0;
	margin-bottom: 0;
}

.mdl-margin--top--m20 {
	margin-top: -20px;
}

.mdl-margin--left--10 {
	margin-left: 10px;
}

.mdl-margin--right--10 {
	margin-right: 10px;
}

.mdl-margin--top--m10 {
	margin-top: -10px;
}

.mdl-margin--bottom--20 {
	margin-bottom: 20px;
}

.mdl-margin--top--40 {
	margin-top: 40px;
}

.mdl-margin--bottom--40 {
	margin-bottom: 40px;
}

.mdl-margin--bottom--120 {
	margin-bottom: 120px;
}

.mdl-margin--bottom--grid-default {
	margin-bottom: 32px;
}

.mdl-padding--bottom--10 {
	padding-bottom: 10px;
}

.mdl-float--left {
	float: left;
}

.mdl-float--right {
	float: right;
}

.mdl-text-align--center {
	text-align: center;
}

.mdl-menu {
	a {
		color: rgba(0,0,0, 0.87);
		text-decoration: none;
	}
}

.mdl-button--fab.mdl-button--mini-fab {
	height: 35px;
    min-width: 35px;
    width: 35px;

    .material-icons {
		font-size: 20px;
    }
}

.mdl-button--clear-button {
	border: 1px solid darken($lightGray, 5%);
	font-weight: normal;
	text-transform: none;
}

.mdl-button--clear-button--disabled {
	color: darken($lightGray, 30%);
}

.mdl-button--small {
	min-height: 24px;
	height: auto;
	line-height: 14px;
	padding: 5px 8px;
	font-size: 12px;
	text-transform: none;
	box-sizing: border-box;

	.material-icons {
		font-size: 14px;
		margin-right: 5px;
		position: relative; 
		top: -1px;
	}
}

.mdl-button--small--normalheight {
	margin-top: 7px;
	margin-bottom: 7px;
}

.mdl-button--disable-shadow {
	box-shadow: none;
}

.mdl-tooltip {
	padding: 4px 8px;
}

.mdl-ripple.is-animating {
    transition: transform 1s cubic-bezier(0, 0, 0.2, 1),
			    width 0.3s cubic-bezier(0, 0, 0.2, 1),
			    height 0.3s cubic-bezier(0, 0, 0.2, 1),
			    opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
}

.mdl-textfield .mce-tinymce {
	margin-top: 10px;
}

.mdl-textfield--own-focus.is-dirty,
.mdl-textfield--own-focus.is-focused {
	&::after {
		background-color: rgb(66,165,245);
		bottom: 20px;
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		transition-duration: 0.2s;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		width: 100%;
	}
}

.mdl-cf:after {
	content: '';
	display: block;
	clear: both;
}

.mdl-button-row { 
	line-height: 36px;  
}

.mdl-button--inline {
	font-weight: normal;
	text-transform: none;
	line-height: inherit;
	height: auto;
	border-radius: 0;
	padding: 0 8px;
}

.mdl-color-picker {
	position: relative;
}

.mdl-color-picker-value {
	position: absolute;
	color: #424242;
	font-size: 13px;
	top: 25px;
    left: 22px;
	z-index: 20;
	pointer-events: none;
}

.mdl-button--fab--text {
	color: #fff;
	font-size: 10px;
	text-transform: uppercase;
}

.mdl-color--grey-666 {
	background: #666;

	&:hover {
		background: #666;
	}
}

.mdl-checkbox__label {
	font-size: 14px;
}

.mdl-checkbox--top-label {
	margin-top: 20px;

	.mdl-checkbox__label {
    	position: absolute;
    	top: -18px;
    	left: 50%;
    	transform: translateX(-50%);
	}

	.mdl-checkbox__focus-helper,
	.mdl-checkbox__box-outline {
		top: 8px;
		left: 50%;
		transform: translateX(-50%);
	}

	.mdl-checkbox__ripple-container { 
		top: -2px;
		left: 50%;
		transform: translateX(-50%);
	}

	.mdl-checkbox__label {
		color: #858585;
		font-size: 12px;
	}

	.mdl-checkbox .mdl-ripple {
		display: block !important;
	}
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
	color: #858585;
}

.mdl-overflow-hidden {
	overflow: hidden;
}

fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline,
.mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
    background: #656565 url("/app/assets/images/tick.svg?embed");
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline,
.mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
    border-color: #656565;
}

.mdl-checkbox {
	&.is-white {
		.mdl-checkbox__box-outline {
			border: 2px solid rgba(255, 255, 255, 0.71);
		}
	
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #fff;
			}
			.mdl-checkbox__tick-outline {
				background: #fff url(/app/assets/images/tick.svg?embed);
			}
		}
	}

	&.is-green {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #00C853;
			}
			.mdl-checkbox__tick-outline {
				background: #00C853 url(/app/assets/images/tick.svg?embed);
			}
		}
	}

	&.is-pink {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #e81d62;
			}
			.mdl-checkbox__tick-outline {
				background: #e81d62 url(/app/assets/images/tick.svg?embed);
			}
		}
	}

	&.is-purple {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #9c27b0;
			}
			.mdl-checkbox__tick-outline {
				background: #9c27b0 url(/app/assets/images/tick.svg?embed);
			}
		}
	}

	&.is-goldy {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #C39A2A;
			}
			.mdl-checkbox__tick-outline {
				background: #C39A2A url(/app/assets/images/tick.svg?embed);
			}
		}
	
	}

	&.is-orange {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #ff5722;
			}
			.mdl-checkbox__tick-outline {
				background: #ff5722 url(/app/assets/images/tick.svg?embed);
			}
		}
	}

	&.is-red {
		&.is-checked {
			.mdl-checkbox__box-outline {
				border-color: #E91E63;
			}
			.mdl-checkbox__tick-outline {
				background: #E91E63 url(/app/assets/images/tick.svg?embed);
			}
		}
	}	
}


.mdl-data-table--no-checkbox tr td:first-child,
.mdl-data-table--no-checkbox tr th:first-child {
	display: none;
}

.mdl-data-table--clean-last-row tbody tr:last-child td {
	border-bottom: none;
}

.mdl-text--label {
	color: #858585;
}

.mdl-overflow--visible {
	overflow: visible !important;
}

.mdl-grid .mdl-grid.mdl-grid--nesting {
	padding: 0;
	// margin: 0 -16px;

	margin-left: -16px;
	margin-right: -16px;
	width: calc(100% + 32px);
}

.mdl-bullet {
	width: 10px;
	height: 10px;
	display: inline-block;
	margin-right: 8px;
	border-radius: 10px;

	&--green {
		background: #4bae54;
	}

	&--red {
		background: #f14633;
	}

	&--orange {
		background: #f79d21;
	}
}

.mdl-font-weight--bold {
	font-weight: 700;
}

.mdl-textfield__input {
	&::-webkit-input-placeholder {
	   color: #858585;
	}

	&:-moz-placeholder { /* Firefox 18- */
	   color: #858585;  
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	   color: #858585;  
	}

	&:-ms-input-placeholder {  
	   color: #858585;  
	}
}

.mdl-width--100 {
	width: 100% !important;
}





//----------------------------------------------------
// # Tabs
//----------------------------------------------------

.mdl-tabs__tab-bar {
	height: 56px;
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	padding: 0 32px;
	position: relative;
	border: none;
	justify-content: flex-start;

	.mdl-switch {
		margin-top: 16px;
		margin-left: auto;
	}

	.mdl-tabs__switches {
		margin-top: 16px;
		

		&.is-right {
			margin-left: auto;

			.mdl-switch {
				margin-left: 40px;
			}
		}

		&.is-left {
			margin-right: auto;

			.mdl-switch {
				margin-right: 40px;
			}
		}

		.mdl-switch {
			margin-top: 0;
		}
	}

}

.mdl-tabs__custom-link {
	position: absolute;
	right: 32px;
	top: 12px;
}

.mdl-tabs__tab {
	font-size: 13px;
	text-align: center;
	white-space: nowrap;
	position: relative;
	line-height: 56px;
	color: rgba(0, 0, 0, 0.54);
	padding: 0 15px;
	text-transform: none;
	text-decoration: none;
	font-weight: 600;
	display: inline-block;
	height: 56px;
	border-radius: 0;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active::after {
	animation: none;
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
	background: rgb(0,0,0);
}





//----------------------------------------------------
// # Form
//----------------------------------------------------

// Green Switch

.mdl-switch--green {
	&.is-checked {
		.mdl-switch__thumb {
			background: #009688;
		}

		.mdl-switch__track {
			background: rgba(#009688, 0.5);
		}
	} 
}


// Purple Switch

.mdl-switch--purple {
	&.is-checked {
		.mdl-switch__thumb {
			background: #9c27b0;
		}

		.mdl-switch__track {
			background: rgba(#9c27b0, 0.5);
		}
	} 
}

// Red Switch

.mdl-switch--red {
	&.is-checked {
		.mdl-switch__thumb {
			background: #b00400;
		}

		.mdl-switch__track {
			background: rgba(#b00400, 0.5);
		}
	}
}


// Light textfield border

.mdl-textfield--light .mdl-textfield__input {
	border-bottom-color: rgba(255,255,255,0.6);
}

// Text field with icon

.mdl-textfield--icon-before {

	.mdl-textfield__icon-before {
	    font-size: 24px;
	    color: #fff;
	    position: absolute; 
	    bottom: 32px;
	    left: 5px;
	}

	.mdl-textfield__input {
	    width: calc(100% - 40px);
	    font-size: 14px;
	    padding: 6px 0 8px 40px;
	}

	.mdl-textfield__label {
	    width: calc(100% - 40px);
	    color: #b9b9b9;
	    font-size: 14px;
	    padding-left: 40px;
	}

}

.mdl-textfield--narrow {
	padding-bottom: 10px;

	.mdl-textfield__label:after {
		bottom: 10px;
	}
}

// Text field standalone error msg

.mdl-textfield__error--stand-alone {
	visibility: visible;
	position: relative;
}


// Full Width input

.mdl-textfield--full-width {
	width: 100%;
}


.mdl-textfield__label::after {
    left: 0;
    width: 100%;
}

.mdl-textfield--color-pink {
	color: #E91E63 !important;
}

.mdl-textfield--color-red {
	color: #d32f2f !important;
}

.mdl-textfield--color-orange {
	color: #ff5722 !important;
}

.mdl-textfield--color-yellow {
	color: #FFB344 !important;
}

.mdl-textfield--color-blue {
	color: #03a9f4 !important;
}

.mdl-textfield--color-purple {
	color: #9c27b0 !important;
}



//----------------------------------------------------
// # MDL block item
//----------------------------------------------------

.mdl-block-item {
	&.is-close {
		.mdl-content-header__arrow {
			transform: rotate(180deg);
		}
	}

	.mdl-content {
		// TODO: Miért került ez bele?
		overflow: auto;
	}

	&[data-translatable="true"] .mdl-custom-tabs {
		position: absolute;
		right: 55px;
    	top: 21px;
	}
}

.mdl-content-header {
	padding: 6px 20px;
	color: #fff;
	min-height: 30px;

	display: flex;
	align-items: center;
	position: relative;

	background: #666;

	&--blue {
		background: $defaultColor !important;
	}

	&--modal {
		background: $defaultColor !important;
	}
	
	&__upload-btn {
		right: 20px;
		top: 13px;
	    position: absolute;

	    .ghost-btn__text::after {
	    	content: attr(data-close-text);
	    	display: inline;
	    }

	    &.is-active {
	    	.ghost-btn__text::after {
				content: attr(data-open-text);
				display: inline;
	    	}
	    }
	}
	
	&__icon {
		font-size: 26px;
		width: 26px;
		height: 26px;
		display: inline-block;
		margin-right: 10px;
	}

	&__icon-translate {
		font-size: 26px;
		width: 26px;
		height: 26px;
		display: inline-block;
		position: absolute;
		top: 16px;
		right: 20px;
	}

	&__title {
		font-size: 20px;
		display: inline-block;
		margin: 0;
	}

	&__arrow {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		line-height: 60px;
		text-align: center;
		font-size: 20px;
		cursor: pointer;
	}

	&__close-btn {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		color: #fff;
		font-size: 30px;
    	text-align: center;
    	line-height: 60px;
		width: 60px;
		border-left: 1px solid #fff;
		height: 100%;
    	padding: 0;
    	border-radius: 0;
	}

	&__edit-icon {
		color: #fff;
		position: absolute;
		top: 17px;
		right: 20px;

		&:hover,
		&:focus {
			opacity: .8;
		}
	}
}






//----------------------------------------------------
// # Postfix label
//----------------------------------------------------

.ref-text {
	background: red;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 50;
	visibility: hidden;
	display: none;
    display: inline-block;
    font-size: 16px;
    font-family: "Helvetica", "Arial", sans-serif;
    margin: 0;
    color: #fff;
}

.mdl-textfield__postfix {
	position: absolute;
	bottom: 25px;
	color: #bcbcbc;
	pointer-events: none;
	user-select: none;

	&.is-fixed {
		right: 0;
		left: auto !important;
		z-index: 10;

		&::before {
			content: '';
			position: absolute;
			top: -6px;
			right: 0;
	        left: -15px;
	        display: block;
	        background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(255,255,255,0.58) 14%, rgba(255,255,255,1) 24%);
	        background: -o-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(255,255,255,0.58) 14%, rgba(255,255,255,1) 24%);
	        background: linear-gradient(to right, rgba(30,87,153,0) 0%, rgba(255,255,255,0.58) 14%, rgba(255,255,255,1) 24%);
	        bottom: 0;
			z-index: -10;
		}
	}
}

.mdl-textfield {
	.mdl-textfield__postfix {
		display: none;
	}
}

.mdl-textfield.is-dirty,
.mdl-textfield.is-focused {
	.mdl-textfield__postfix {
		display: inline-block;
	}
}






//----------------------------------------------------
// # Select
//----------------------------------------------------

.mdl-select {
	.mdl-textfield__label {
		color: #858585;
		font-size: 12px;
		top: 4px;
	}
}





//----------------------------------------------------
// # Required field
//----------------------------------------------------

.mdl-required-field {
	.mdl-required-marker {
		margin-left: 5px;
	}

	&.is-focused {
		.mdl-required-marker {
			color: #F44336;
		}
	}
}

.mdl-radio-wrapper {
	.mdl-textfield__label {
		top: 4px;
		font-size: 12px;
		color: #858585;
	}

	.mdl-radio {
		margin-right: 10px;
		margin-top: 5px;
	}
}

.mdl-switch-wrapper {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 4px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0,0,0, 0.12);

	.mdl-switch {
		display: flex;
		align-items: center;
		
		
		width: 100% !important;
	}
	
	.mdl-switch__label {
		font-size: 14px;
	}
}

.mdl-radio--green {
	&.is-checked .mdl-radio__outer-circle {
		border-color: #00c853;
	}

	.mdl-radio__inner-circle {
		background: #00c853;
	}

	.mdl-radio__ripple-container .mdl-ripple {
	    background: #00c853;
	}
}

.mdl-radio--red {
	&.is-checked .mdl-radio__outer-circle {
		border-color: #d32f2f;
	}

	.mdl-radio__inner-circle {
		background: #d32f2f;
	}

	.mdl-radio__ripple-container .mdl-ripple {
	    background: #d32f2f;
	}
}





//----------------------------------------------------
// # Custom Tabs
//----------------------------------------------------

.mdl-custom-tabs {

}

.mdl-custom-tabs__tab {
	cursor: pointer;
	color: rgba(255,255,255,0.8);
	margin: 0 5px;

	&.is-active {
		color: #fff;
	}
}

.mdl-custom-tabs__panels {

}

.mdl-custom-tabs__panel {
	display: none;
	visibility: hidden;

	&.is-active {
		display: block;
		visibility: visible;
	}
}





//----------------------------------------------------
// # Visibility helpers
//----------------------------------------------------

.mdl-hide--x-small {
	@media (max-width: $small-screen-max) {
		display: none !important;
	}
}

.mdl-hide--small {
	@media (min-width: $small-screen-min) and (max-width: $medium-screen-max) {
		display: none !important;
	}
}

.mdl-hide--medium {
	@media (min-width: $medium-screen-min) and (max-width: $large-screen-max) {
		display: none !important;
	}
}

.mdl-hide--large {
	@media (min-width: $large-screen-min) and (max-width: $x-large-screen-max) {
		display: none !important;
	}
}

.mdl-hide--x-large {
	@media (min-width: $x-large-screen-min) {
		display: none !important;
	}
}

.mdl-show--x-small {
	@media (max-width: $small-screen-max) {
		display: block !important;
	}
}

.mdl-show--small {
	@media (min-width: $small-screen-min) and (max-width: $medium-screen-max) {
		display: block !important;
	}
}

.mdl-show--medium {
	@media (min-width: $medium-screen-min) and (max-width: $large-screen-max) {
		display: block !important;
	}
}

.mdl-show--large {
	@media (min-width: $large-screen-min) and (max-width: $x-large-screen-max) {
		display: block !important;
	}
}

.mdl-show--x-large {
	@media (min-width: $x-large-screen-min) {
		display: block !important;
	}
}


// Input field with icon 

.mdl-textfield {
	&--icon-right {
		
	}

	&__icon {
		position: absolute;
		right: 10px;
		top: 24px;

		&::before {
			content: '';
			position: absolute;
			top: -2px;
			right: -12px;
			bottom: 0;
			left: -2px;
			background: #fff;
			display: block;
			z-index: 1;
		}

		i {
			font-size: 18px;
			position: relative;
			z-index: 2;
		}

		&--green {
			color: #4CAF50;
		}

		&--blue {
			color: #03a9f4;
		}
	}
}

// Border button

.mdl-button--bordered {
	border-radius: 2px;
    padding: 5px 10px;
	text-decoration: none;
    font-size: 13px;

    &.is-blue {
		color: $defaultColor;
		border: 1px solid;

		&:hover,
	    &:focus {
	    	background: $defaultColor;
	    	color: #fff;
	    	border-color: $defaultColor;
	    }
    }

    &.is-orange {
    	color: #ffa521;
    	border: 1px solid;

    	&:hover,
	    &:focus {
	    	background: #ffa521;
	    	color: #fff;
	    	border-color: #ffa521;
	    }
    }

	&__icon {
		font-size: 14px;
		position: relative;
	    top: 2px;
	    margin-right: 3px;
	}
}

.mdl-button--medium {
	.material-icons {
		font-size: 14px;
	    margin-right: 5px;
	    position: relative;
	    top: -1px;
	}
}

// mdl-text with button
.mdl-textfield-with-button {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;

	.mdl-textfield--full-width {
		width: calc(100% - 42px);
	}
}