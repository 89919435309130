//--------------------------
// # Variables
//--------------------------


// Defaults

$lightGray: #f5f5f5;
$darkGray: #263238;
$defaultFont: 'Roboto', sans-serif;
$defaultColor: #2196f3;
$sidenavAnimationTiming: cubic-bezier(.55,0,.55,.2);


// MDL

$checkbox-color: #03A9F4;
$color-primary: "66,165,245";
$image_path: '/app/assets/images';


// Perfect Scrollbar

$ps-scrollbar-x-rail-height: 4px;
$ps-scrollbar-x-height: 4px;

$ps-scrollbar-y-rail-width: 4px;
$ps-scrollbar-y-width: 4px;

$ps-scrollbar-x-hover-height: 4px;
$ps-scrollbar-y-hover-width: 4px;


$grid-desktop-gutter: 32px;
$grid-desktop-margin: 32px;


// @Media

$small-screen-min: 760px;
$medium-screen-min: 992px;
$large-screen-min: 1200px;
$x-large-screen-min: 1600px;

$small-screen-max: 759px;
$medium-screen-max: 991px;
$large-screen-max: 1199px;
$x-large-screen-max: 1599px;

$grid-l-desktop-breakpoint: 1200px;
$grid-x-l-desktop-breakpoint: 1600px;



//--------------------------
// # Mixins
//--------------------------

@mixin clearfix() {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}