
//----------------------------------------------------
// # Data Table
//----------------------------------------------------



//---------------------------
//
// TABLE OF CONTENT 
//
// # Helpers
// # Generic
// # Main Data Table modify class
// # Table footer
// # Name & Role block
// # Status Block
//
//---------------------------



//------------------------------------------------------------------------------
// # Helpers
//------------------------------------------------------------------------------

.td-width--200 {
	width: 200px;
}

.td-width--400 {
	width: 400px;
}

.td-width--500 {
	width: 500px;
}

.td-width--600 {
	width: 600px;
}

.td-width--800 {
	width: 800px;
}

.td-align--left {
	text-align: left !important;
}

.td-align--right {
	text-align: left !important;
}

.data-table-link {
	color: #737373;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.data-table-save-btn {
	color: $defaultColor;
}

.data-table-highlight-row {
	height: auto !important;

	td {
		height: auto !important;
		background: #f5f5f5;
		padding: 5px 18px !important;
	}

	> td {
		.mdl-checkbox {
			display: none;
		}
	}
}

.data-table-mlt {
	margin: 0;
	line-height: 1.2em;
}

.dt-width--200 {
	width: 200px;
	white-space: normal;
	margin: auto;
}

.severity {
	&--0 {
		color: #e8e8e8;
	}

	&--1 {
		color: #00c853;
	}

	&--2 {
		color: #ff9800;
	}

	&--3 {
		color: #0091ea;
	}

	&--4 {
		color: #e91e63;
	}

	&--5 {
		color: #d32f2f;
	}
}


.counter-bubble {
	display: inline-block;
	height: 20px;
	min-width: calc(20px - 6px);
	font-size: 10px;
	color: #fff;
	line-height: 20px;
	text-align: center;
	padding-left: 3px;
	padding-right: 3px;
	background: $defaultColor;
	border-radius: 20px;
}





//------------------------------------------------------------------------------
// # Generic
//------------------------------------------------------------------------------

.mdl-data-table__action-column {
	width: 40px;
}

.mdl-checkbox {
	.mdl-ripple {
		display: none !important;
	}
}

.data-table-status {
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 10px;
	text-align: center;


	&__icon {
		color: #fff;
		font-size: 12px;
		line-height: 20px;
	}

	&--ok {
		background: #4bae54;
	}

	&--nok {
		background: #f14633;

		.data-table-status__icon {
			position: relative;
		}
	}
}

.mdl-data-table__empty-msg {
	padding: 22px;
	margin: 0;
	font-weight: 700;
	background: #f1f1f1;
}






//------------------------------------------------------------------------------
// # Main Data Table modify class
//------------------------------------------------------------------------------

#js-data-table {
	counter-reset: dataTableSelectedRows;
}

.mdl-data-table--main {
	width: 100%;
	border: none;
	// counter-reset: dataTableSelectedRows;

	td .mdl-checkbox__input:checked {
		counter-increment: dataTableSelectedRows;
	}

	thead.table-header {
		//border-bottom: 3px solid $defaultColor;
		th {
			padding-top: 16px;
			padding-bottom: 16px;
			font-weight: 600;

			.data-table-title-wrapper {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				min-height: 30px;
			}

			.data-table-title-wrapper--center {
				justify-content: center;
			}

			.data-table-title {
				line-height: 1.3em;
			}

			.data-table-order {
				margin-left: 5px;
			}
		}
	}

	tbody {
		tr.is-selected {
			background: #f6f6f6;
		}
		
		tr:hover {
			background: #efefef;
		}
	}

	th:first-of-type,
	td:first-of-type {
		width: 40px; 
	}

	td,
	th {
		text-align: center;
		background-clip: padding-box;
	}

	td {
		padding: 22px 18px;
		color: #737373;
		border-top: 1px solid #d9d9d9;
    	border-bottom: 1px solid #d9d9d9;
	}


	.mdl-data-table__cell--align-center {
		text-align: center;
	}

	a {
		color: #737373;
		font-weight: 400;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	
	@media (max-width: 1600px) {
		.mdl-data-table__comments-column,
		.mdl-data-table__last-ip-column,
		.mdl-data-table__country-column,
		.mdl-data-table__age-column,
		.mdl-data-table__quantity-column,
		.mdl-data-table__created-column,
		.mdl-data-table__pdr-column,
		.mdl-data-table__allowedareas-column,
		.mdl-data-table__showon-column,
		.mdl-data-table__decoration-column,
		.mdl-data-table__updated-column,
		.mdl-data-table__size-column,
		.mdl-data-table__type-column,
		.mdl-data-table__postrating-column,
		.mdl-data-table__coverstory-column,
		.mdl-data-table__covervideo-column,
		.mdl-data-table__zerolanding-column,
		.mdl-data-table__chpid-column,
		.mdl-data-table__title-column,
		.mdl-data-table__reply-column,
		.mdl-data-table__quote-column,
		.mdl-data-table__chcid-column,
		.mdl-data-table__new_client-column,
		.mdl-data-table__country-column,
		.mdl-data-table__date-column,
		.mdl-data-table__status-column,
		.mdl-data-table__payment-column {
			display: none;
		}
	}
}

.mdl-data-table--narrow-padding {
	td {
		padding: 12px 18px;
	}
}

.mdl-data-table--price-matrix {
	td {
		padding: 10px 18px;
	}

	.mdl-textfield {
		text-align: center;
		padding-top: 0;
		padding-bottom: 0;
	}

	.mdl-textfield__label {
		font-size: 12px;
	}

	.mdl-textfield--floating-label .mdl-textfield__label {
		transition: none;
	}

	.mdl-textfield__label:after {
		bottom: 0;
	}

	.mdl-textfield__postfix {
		bottom: 5px;
	}

	.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
	.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
	.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
		color: transparent;
	}

	.mdl-textfield__input {
		font-size: 13px;
	}

	.mdl-data-table__price-column {
		border-right: 1px solid #e4e4e4;
		border-left: 1px solid #e4e4e4;
	}

	.mdl-textfield__postfix {
		font-size: 13px;
	}
}


.mdl-data-table--price-matrix-simple {
	th {
		width: 200px !important;
	}

	td {
		padding-top: 18px;
		padding-bottom: 18px;
	}
}






//------------------------------------------------------------------------------
// # Table footer
//------------------------------------------------------------------------------

.table-footer {
	color: #737373;
	background: #fff;
	display: flex;
    border-bottom: 1px solid #d9d9d9;
}

.table-footer__left {
	padding: 30px 22px;
	width: 50%;
	display: flex;
	align-items: center;
}

.table-footer__right {
	padding: 30px 22px;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

// Table footer counter

.data-table-footer__counter {
	min-width: 20px;
	height: 20px;
	background: $defaultColor;
	border-radius: 2px;
	color: #fff;
	font-size: 10px;
	padding: 0;
	display: inline-block;
	font-weight: 400;
	line-height: 20px;
	text-align: center;

	span::before {
		content: counter(dataTableSelectedRows);
	}
}


// Table footer actions

.data-table-footer__actions {
	display: inline-block;
	margin-left: 10px;

	a {
		text-decoration: none;
	}
}

.table__product-image {
	width: 42px;
	height: 42px;
	border-radius: 21px;
}


// Table footer pager

.data-table-footer__pager {
	display: inline-block;
	margin-left: 40px;
}

.data-table-footer__pager-sum {
	margin-right: 20px;
}

.data-table-actions-delete {
	color: #E91E63;

	&:hover,
	&:active {
		color: #AD1457;
	}
}

.data-table-footer__rows-per-page {
	display: inline-block;
	min-width: 170px;
	line-height: 28px;

	.select2-container {
		max-width: 50px;
		float: right;
	}

	.select2-container--default .select2-selection--single {
		border-bottom: none;
	}

	/*span {
		margin-right: 20px;
	}*/
}





//------------------------------------------------------------------------------
// # Name & Role block
//------------------------------------------------------------------------------

.table-name-role__name {
	color: #252525;
	font-weight: 500;
}

.table-name-role__role {
	&--user {
		color: #737373;
	}

	&--moderator {
		color: #E91E63;
	}

	&--support {
		color: #03A9F4;
	}

	&--admin {
		color: #FFC107;
	}
}





//------------------------------------------------------------------------------
// # Status Block
//------------------------------------------------------------------------------

.table__status {
	display: inline-block;
	width: 42px;
	height: 42px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 21px;
	line-height: 42px;
	text-align: center;

	&--act {
		background: #4CAF50;
	}

	&--ban {
		background: #F44336;
	}

	&--sus {
		background: #FFC107;
	}

	&--flg {
		background: #E91E63;
	}
}





//------------------------------------------------------------------------------
// # Order columns
//------------------------------------------------------------------------------

.data-table-order {
	display: inline-block;
	height: 12px;
	width: 26px;
	position: relative;
	margin-left: 2px;

	a {
	    color: rgba(0,0,0,0.54);
	    text-decoration: none;
	    display: inline-block;
    	height: 12px;
    	width: 13px;
    	overflow: hidden;
    	user-select: none;

	    &:hover,
	    &:focus,
	    &:active {
	    	text-decoration: none;
	    	color: rgba(0,0,0,0.84);
	    }
	}

	.material-icons {
		font-size: 24px;
		position: relative;
		top: -6px;
    	left: -5px;
		user-select: none;
		cursor: pointer;
	}

	.is-active {
		color: $defaultColor;
	}
	
	&__desc {
		position: absolute;
		left: 0;
		top: 0;

		.material-icons {
			top: -7px;
		}
	}

	&__asc {
		position: absolute;
		right: 0;
		top: 0;
	}
}


.log-data-table {
	border-collapse: collapse;
    width: 100%;

    tr:last-of-type {
    	td,
    	th {
    		border: none;
    	}
    }

    tr:hover {
		td,
		th {
			background: #f1f1f1;
		}
    }

	td,
	th {
    	border-bottom: 1px solid #d9d9d9;
    	padding: 8px 10px;
	}

	th {
		text-align: right;
		width: 120px;
	}

	.old-data {
		color: #999;
	}

	.new-data {
		color: rgb(255,64,129);
	}
}