
//------------------------------------------------------------------------------
// # Login Screen
//------------------------------------------------------------------------------

.login-screen {
    color: #d0d0d0;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
    text-align: center;
    overflow: hidden;

    .remember-me-checkbox {
    	width: 110px;
    }

    &--lock {
    	position: fixed;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	z-index: 10031;
    }

	&__logo {
		width: 100%;
		padding: 130px 0 25px 0;
	}

	&__content {
		width: 100%;
		margin: 0 0 30px 0;
		background-color: rgba(28, 21, 65, 0.35);
	}

	&__login-box {
	    display: inline-block;
	    width: 100%;
	    margin: 10px;
	    max-width: calc(370px - 60px);
	    padding: 30px;
	    position: relative;
	}

	&__intro-text {
		color: #eee;
		margin-bottom: 5px;
	}

	&__captcha-text {
		line-height: 1.4em;
		margin-top: 10px;
		margin-bottom: 25px;
		color: #fff;
		font-size: 13px;
	}

	
	// Corners marker

	&__corner {
	    width: 8px;
	    height: 8px;
	    position: absolute;

	    &--tl {
		    top: 0;
		    left: 0;
		    border-top: 2px solid #ece7ff;
		    border-left: 2px solid #ece7ff;
		}

		&--tr {
		    top: 0;
		    right: 0;
		    border-top: 2px solid #ece7ff;
		    border-right: 2px solid #ece7ff;
		}

		&--bl {
		    bottom: 0;
		    left: 0;
		    border-bottom: 2px solid #ece7ff;
		    border-left: 2px solid #ece7ff;
		}

		&--br {
		    bottom: 0;
		    right: 0;
		    border-bottom: 2px solid #ece7ff;
		    border-right: 2px solid #ece7ff;
		}
	}

	// Error messages

	&__block-msg {
		color: #F44336;
		margin: 0;
		font-size: 16px;
	}

	&__text-error {
		color: rgb(213,0,0);
	    font-size: 12px;
	    margin-top: 3px;
	    display: block;
	    text-align: left;
	}


	// Captcha

	.g-recaptcha {
		margin-top: 10px;

		> div {
			margin-left: 4px;
		}
	}

	
	// Generic

	.mdl-textfield {
		margin-left: 0;
	}

	a {
	    text-transform: uppercase;
	    text-decoration: underline;
	    color: #d0d0d0;

	    &:hover,
	    &:focus {
	    	color: #fff;
    		text-decoration: none;
	    }
	}

	// Lock Screen Image

	&__lock-image {
	    display: flex;
	    align-items: center;
	    margin-bottom: 15px;

	    img {
		    float: left;
		    margin: 0 20px 0 0;
		    width: 75px;
		    height: 75px;
		}

		p {
		    text-align: left;
		    margin: 0;
		    line-height: 1.4em;
		}
	}


	// Footer

	&__footer {
		p {
			color: #d0d0d0;
    		text-shadow: 1px 1px 0px rgba(102,102,102, 0.5);
		}
	}


	// Login BTN

	&__login-button {
	    font-size: 18px;
	    width: 270px;
	    border: 0;
	    text-transform: uppercase;
	    cursor: pointer;
	    margin: 25px 0 20px 0;
	    padding: 11px 21px;
	    color: #fff;
	    position: relative;
	    text-align: left;
	    outline: none;
	    overflow: hidden;

	    i {
		    position: absolute;
		    top: 9px;
		    right: 12px;
		    z-index: 10;
		}

		&--green {
			background-color: #00b754;

			&:hover,
			&:focus {
				background-color: #00d360;

				&:after {
					border-bottom-color: #14b43d;
					border-left-color: transparent;
				}
			}

			&::after {
				display: block;
			    position: absolute;
			    top: -10px;
			    right: -10px;
			    content: '';
			    color: #fff;
				width: 55px;
				height: 0;
				border-bottom: 60px solid #139837;
				border-left: 20px solid transparent;
			}
		}

		&--gray {
			background-color: #848484;

			&:hover,
			&:focus {
				background-color: #9d9d9d;

				&:after {
					border-bottom-color: #414141;
					border-left-color: transparent;
				}
			}

			&::after {
				display: block;
			    position: absolute;
			    top: -10px;
			    right: -10px;
			    content: '';
			    color: #fff;
				width: 55px;
				height: 0;
				border-bottom: 60px solid #3b3b3b;
				border-left: 20px solid transparent;
			}
		}
	}
}